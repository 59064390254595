.cartao .cartao-cabecalho .btn-card {
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: transparent;
    border          : none;
}

.cartao .cartao-cabecalho .btn-card.ok>svg {
    fill: var(--success);
}

.cartao .cartao-cabecalho .btn-card.error>svg {
    fill: var(--error);
    margin:1.5px 7px;
}

.cartao .cartao-cabecalho .btn-card.updating>svg {
    fill: var(--updating);
}
.cartao .cartao-cabecalho .btn-card.offline>svg {
    fill: var(--offline);
}
.cartao .cartao-cabecalho .btn-card.adapter-nao-conectado>svg {
    fill: var(--offline);
}

.cartao .cartao-cabecalho .btn-card.nao-provisionada>svg {
    fill: var(--nao-provisionada);
}

.cartao .cartao-cabecalho .btn-card.error .btn-card.error {
    width: fit-content;    
    color: var(--fontColors);
    
    margin: 0 -4px -2px 0;
    border-radius: 4px;
    padding: 2px;
}

.cartao .cartao-cabecalho .btn-card.error .btn-card.error>span {
    color: var(--fontColors);
    font-size: 15px;
    margin-right: 7px;
}

.cartao .cartao-cabecalho .btn-card.error:nth-child(2) {
    cursor:default;
}

.cartao .cartao-cabecalho .btn-card.error>button:hover{
    border: none;
    box-shadow: 0px 0px 0px 0px #000000;
    margin-top: -3px;
}

.box-olt .cartao .cartao-cabecalho .btn-card.error .btn-card.error:hover {
    box-shadow: 0px 1px 3px 1px #00000067;
    margin-top: -3px;
    border: 0.5px solid var(--error);
}
