.table-container {
    overflow-x: auto;
    padding: 10px;
    align-items: center;
    display: flex;
    align-content: center;
    align-self: center;
}

.table {
    width: fit-content;
    border-collapse: collapse;
    background-color: white;
}

.table th,
.table td {
    border: 1px solid white;
    padding: 10px;
    text-align: center;
    color: var(--page-color);
    background-color: var(--title-container-background);
}

.table th {
    border: 2px solid white;
}

.table>tbody>tr:hover {
    background-color: var(--title-container-background);
}

.table>tbody>tr>td {
    text-align: center;
}