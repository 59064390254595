.box-plano {
    position: relative;
}

.modalPlanoNovo {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
}

.modalPlanoNovo .planCreate {
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    border         : 1.5px solid var(--input-border-color);
    max-height     : 200px;
    border-radius  : 10px;
    padding        : 8px 8px;
    margin-right   : 15px;
    flex-grow      : 1;
}

.modalPlanoNovo .advancedConfig {
    border       : 1.5px solid var(--input-border-color);
    min-height   : 330px;
    min-width    : 350px;
    border-radius: 10px;
    padding      : 0px 8px;
    flex-grow    : 7;
    position     : relative;
}

.modalPlanoNovo .advancedConfig h5 {
    margin-top : 5px;
    margin-left: 8px;
}

.modalPlanoNovo .planTable {
    /*     border       : 1.5px solid var(--input-border-color);*/
    min-height   : 60px;
    width        : 100%;
    border-radius: 10px;
    margin-top   : 15px;
    max-height   : 150px;
    overflow-x   : auto;
}

.firstLine {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    margin-left   : -8px;
    margin-right  : -8px;
}

.pppoeContent input,
.planCreate input,
.brigdeContent input {
    height: 45px;
}

.brigdeContent,
.pppoeContent {
    position  : absolute;
    right     : 0px;
    width     : 100%;
    height    : 100%;
    opacity   : 1;
    transition: opacity 0.1s 0.5s, width 0.1s, height 0.1s;
    overflow-y: auto;
    overflow-x: hidden;
    overflow-y: overlay;
}

.pppoeContent .secondLine {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    margin-left    : -8px;
    margin-right   : -8px;
}

.pppoeContent .custom-control-checkbox {
    margin: 5px 0 5px 0;
}

.pppoeContent .bandwidthControl {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    margin-left    : -8px;
    margin-right   : -8px;
    min-width      : 200px;
}

#btnSalvarPPPoE,
#btnCencelarPPPoE {
    margin-bottom: 8px;
}

.brigdeContent .secondLine {
    display       : flex;
    flex-direction: column;
    margin-top    : 5px;
}

.brigdeContent .secondLine .custom-control-radio:first-of-type {
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    margin-top     : -20px;
    width          : 47%;
}

.brigdeContent .bandwidthControl {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    margin-left    : -8px;
    margin-right   : -8px;
    min-width      : 200px;
}

.checkLanLine {
    display       : flex;
    flex-direction: row;
    width         : 52%;
    margin-right  : -20px;
}

#btnSalvarBridge,
#btnCancelarBridge {
    margin-bottom: 8px;
}

#btnSalvarPPPoE:hover,
#btnSalvarBridge:hover,
#btnCancelarBridge:hover,
#btnCancelarPPPoE:hover {
    color: white;
}

.planBtn {
    display        : flex;
    justify-content: center;
    width          : 100%;
    display        : absolute;
}

.pppoeContent .planBtn {
    margin-top: -3px;
}

.planBtn button {
    margin : 0 5px;
    padding: 0.75vh 2vh
}

.hidePlan {
    width     : 0;
    height    : 0;
    opacity   : 0;
    visibility: hidden;
    transition: opacity 0.5s, width 1s 0.5s, height 1s 0.5s;
}

.planTable .modern-table button {
    background-color: transparent !important;
}

.pppoeContent .box-form>button {
    top: 20px;
}

.brigdeContent .box-form .invalid-feedback.show-feedback {
    margin-top: 2px;
}

.hideAdvancedOption {
    width     : 0;
    height    : 0;
    opacity   : 0;
    visibility: hidden;
    transition: opacity 0.3s, width 0.5s 0.5s, height 0.5s 0.5s;
}

/* Desfazer essa parte quando tiver a atividade do back de downstream */
.bandwidthControl .col-md-6:nth-child(2) .box-form-select>button:disabled {
    border: 1px solid red;
}