.dark-theme {
    --modal-header-color: #fff;

    --primary-background      : #389CFF;
    --primary-background-hover: #0365c7;
    --primary-color           : #fff;
    --menu-icon-color         : #ffffff;
    --menu-icon-color-hover   : rgba(255, 255, 255, 0.50);

    --secondary-background      : #3e5055;
    --secondary-background-hover: #283438;
    --button-border             : #283438;
    --secondary-color           : #fff;
    --intelbrasLogo             : #ffff;
    --buttonBackground          : #3e5055;

    --fontColors: #fff;

    --transparent-background      : rgba(255, 255, 255, 0);
    --transparent-background-hover: rgba(255, 255, 255, 0.25);
    --transparent-color           : rgba(255, 255, 255, 1);

    --success-background      : #048a40;
    --success-background-hover: #007724;
    --success-color           : #fff;

    --error-background      : #940404;
    --error-background-hover: #640101;
    --error-color           : #fff;

    --warning-background      : #c47500;
    --warning-background-hover: #a06000;
    --warning-color           : #fff;

    --page-background      : #22262b;
    --page-login-background: #121212;
    --page-color           : #fff;

    --footer-background: #222d31;
    --footer-color     : #fff;

    --header-background      : #2d3036;
    --header-background-hover: #222d31;
    --header-color           : #fff;

    --sidebar-background       : #2D3036;
    --sidebar-background-hover : #22262b;
    --sidebar-background-mobile: #2D3036;
    --sidebar-color            : #fff;

    --menu-background      : #222d31;
    --menu-color           : #fff;
    --menu-background-hover: #2e3a3f;
    --menu-color-hover     : #fff;

    --select-box-hover: #3e5055;
    --select-box-color: #fff;

    --scroll-background : #1d1d1d;
    --scroll-track      : #4d4d4d;
    --scroll-track-hover: #6b6b6b;

    --hr-color: #fff;

    --content-background : #1e1e1e;
    --content-color      : #fff;
    --content-color-hover: #fff;

    --list-header-background: #283438;
    --list-background       : #1e1e1e;
    --list-background-hover : #28343854;
    --list-background-border: #283438;
    --list-color            : #fff;
    --list-content-color    : #fff;

    --form-background      : #e1e1f2;
    --form-background-hover: #2e2e2e;
    --form-color           : #737380;
    --form-color-hover     : #fff;
    --form-border          : 1px solid #e1e1f2;

    --box-shadow : 0;
    --card-border: 1px solid rgba(250, 250, 250, 0.2);

    --modal-bg   : #222;
    --modal-bgo  : #000000c9;
    --modal-bgh  : #444;
    --modal-color: #fff;

    --placeholder-color: #FFF;

    --container-background: #22262b;

    --auth-page-background                    : #2d3036;
    --auth-page-container-background          : rgba(0, 0, 0, 0.2);
    --auth-page-first-column-color            : #FFF;
    --auth-page-second-column-color           : #FFF;
    --auth-page-before-background             : #43484E;
    --auth-page-btn-color                     : #43484E;
    --auth-page-btn-border                    : #43484E;
    --auth-page-btn-background                : #FFF;
    --auth-page-btn-hover-background          : #505050;
    --auth-page-btn-first-column-hover-border : #FFF;
    --auth-page-btn-second-column-hover-border: #FFF;
    --auth-page-btn-second-column-hover-color : #FFF;
    --auth-page-btn-box-shadow-color          : #00000029;
    --auth-page-color                         : #FFF;
    --auth-page-intelbras-normal-icon-color   : #FFF;
    --auth-page-intelbras-emphasis-icon-color : #FFF;
    --auth-page-sig-cloud-normal-icon-color   : #FFF;
    --auth-page-link-color                    : #FFF;
    --auth-page-text-border-bottom-color      : #FFF;

    --select-background  : #22262B;
    --select-color       : #FFF;
    --select-hover       : #2D3036;
    --select-border-color: rgba(255, 255, 255, .5);

    --modal-bg-novo           : #2D3036;
    --modal-header-line       : #707070;
    --modal-x-simbol          : #d91616;
    --modal-x-simbol-hover    : #fff;
    --modal-ico-border        : #fff;
    --modal-ico-border-hover  : #d91616;
    --modal-ico-close-bg      : #fff;
    --modal-ico-close-bg-hover: #d91616;

    --secondary-color-novo           : #6A6A6A;
    --secondary-background-novo      : #fff;
    --secondary-background-hover-novo: #858585;
    --secondary-color-hover-novo     : #fff;

    --success-background-novo      : #389CFF;
    --success-color-novo           : #fff;
    --success-background-hover-novo: #007FFE;

    --table-color                     : #fff;
    --table-header-background         : #2d3036;
    --table-header-shadow             : #00000029;
    --table-body-border-color         : rgba(112, 112, 112, 0.2);
    --table-row-background-color      : #22262b;
    --table-row-background-hover-color: #2d3036;
    --table-column-border-color       : rgba(255, 255, 255, 0.1);
    --table-header-column-border-color: #fff;
    --table-header-border             : none;
    --table-actions-divisor-color     : #FFF;
    --table-button-background-color   : #389cff;
    --subtable-background             : #171a1d;

    --success         : #00a335;
    --error           : #ff0009;
    --updating        : #389cff;
    --offline         : #787878;
    --nao-provisionada: #507888;
    --problema-optico : #f3d62f;
    --nao-suportada   : #ff6b26;

    --card-background             : #2d3036;
    --card-text-color             : #fff;
    --card-border-ok              : none;
    --card-border-error           : none;
    --card-border-updating        : none;
    --card-border-offline         : none;
    --card-border-nao-provisionada: none;
    --card-section-divisor-color  : #848689;
    --card-button-color           : #fff;
    --card-button-border          : #848689;

    --sfp-optical-module-status-bg   : #fff;
    --sfp-optical-module-status-color: #00a335;

    --modal-header-background-color   : #2D3036;
    --modal-header-button-text-color  : #FFF;
    --modal-header-button             : #3d4149;
    --modal-header-button-hover       : rgba(125, 125, 125, 1);
    --modal-header-button-border-color: #ffffff1e;
    --modal-locales                   : rgba(255, 255, 255, 0.06);

    --title-container-background: #2D3036;
    --page-indicator            : #2d3036;

    --font-toast-success        : #fff;
    --font-toast-error          : #fff;
    --close-toast-buttom-success: #fff;
    --close-toast-buttom-error  : #fff;

    --svg-input-button-color      : #FFF;
    --svg-input-button-hover-color: #737380;

    --input-placeholder-color: rgba(255, 255, 255, .3);
    --input-border-color     : rgba(255, 255, 255, .5);

    --input-in-select-svg-color   : rgba(255, 255, 255, .5);
    --input-in-select-border-color: rgba(255, 255, 255, .5);
    --input-in-select-text-color  : #FFF;

    --alert-hr-color        : #707070;
    --alert-background-color: #22262B 0% 0% no-repeat padding-box;
    --alert-border-color    : #707070;

    --dropdown-svg-color: #FFF;

    --olt-informacao-background-color: #2D3036;
    --olt-informacao-border-color    : transparent;
    --olt-informacao-box-shadow      : 0px 3px 6px #00000029;
    --olt-informacao-button-color    : #FFF;
    --olt-informacao-divisor-color   : #FFF;

    --meus-dados-icon-color: #FFF;

    --assinante-servicos-background-color: #2D3036;
    --assinante-servicos-border-color    : transparent;
    --assinante-servicos-box-shadow      : 0px 3px 6px #00000029;

    --tooltip-background-color: rgba(0, 0, 0, 0.9);
    --tooltip-color           : #fff;
}

.light-theme {
    --modal-header-color: #2b2b2b;

    --primary-background      : #389CFF;
    --primary-background-hover: rgb(34, 103, 172);
    --primary-color           : #fff;
    --menu-icon-color         : #fff;
    --menu-icon-color-hover   : rgba(255, 255, 255, 0.50);

    --secondary-background      : #fff;
    --secondary-background-hover: #283438ef;
    --button-border             : #28343825;
    --secondary-color           : #425559;
    --intelbrasLogo             : #fff;
    --buttonBackground          : #adb6bb;

    --fontColors: #425559;

    --transparent-background      : rgba(255, 255, 255, 0);
    --transparent-background-hover: rgba(255, 255, 255, 0.25);
    --transparent-color           : rgba(255, 255, 255, 1);

    --success-background      : #048a40;
    --success-background-hover: #007724;
    --success-color           : #fff;

    --error-background      : #e65050;
    --error-background-hover: #e61c1c;
    --error-color           : #fff;

    --warning-background      : #ffe261;
    --warning-background-hover: #ffd61f;
    --warning-color           : #fff;

    --page-background      : #e2e5e6;
    --page-login-background: #eceaea;
    --page-color           : #fff;

    --footer-background: #00a335;
    --footer-color     : #fff;

    --header-background      : #00a335;
    --header-background-hover: #03822c;
    --header-color           : #1e1e1e;

    --sidebar-background       : #fcfcfc70;
    --sidebar-background-hover : #fff;
    --sidebar-background-mobile: #fcfcfc;
    --sidebar-color            : #fff;

    --menu-background      : #f5f5f5;
    --menu-color           : #2b2b2b;
    --menu-background-hover: #2e3a3f;
    --menu-color-hover     : #fff;

    --select-box-hover: #cdcdcd;
    --select-box-color: #2b2b2b;

    --scroll-background : transparent;
    --scroll-track      : #adb6bb;
    --scroll-track-hover: #cdcdcd;

    --hr-color: #fff;

    --content-background : #f7f7f7;
    --content-color      : #fff;
    --content-color-hover: #161414;

    --list-header-background: #adb6bb;
    --list-background       : #f0f0f0;
    --list-background-hover : #cdcdcd;
    --list-background-border: #283438;
    --list-color            : #fff;
    --list-content-color    : #2b2b2b;

    --form-background      : #adb6bb;
    --form-background-hover: #2e2e2e;
    --form-color           : #737380;
    --form-color-hover     : #555555;
    --form-border          : 1.5px solid #adb6bb;

    --box-shadow : 0 10px 10px rgb(126 126 126 / 18%);
    --card-border: 1px solid rgba(0, 0, 0, 0.1);

    --modal-bg   : #fcfcfc;
    --modal-bgo  : #000000c9;
    --modal-bgh  : #fcfcfc;
    --modal-color: #2b2b2b;

    --placeholder-color: #048a40;

    --container-background: #fcfcfc;

    --auth-page-background                    : #fff;
    --auth-page-container-background          : rgba(255, 255, 255, 0.6);
    --auth-page-first-column-color            : #fff;
    --auth-page-second-column-color           : #048a40;
    --auth-page-before-background             : #048a40;
    --auth-page-btn-color                     : #048a40;
    --auth-page-btn-border                    : #048a40;
    --auth-page-btn-background                : #fff;
    --auth-page-btn-hover-background          : #048a40;
    --auth-page-btn-first-column-hover-border : #fff;
    --auth-page-btn-second-column-hover-color : #fff;
    --auth-page-btn-second-column-hover-border: #fff;
    --auth-page-btn-box-shadow-color          : #00000029;
    --auth-page-color                         : #fff;
    --auth-page-link-color                    : #048a40;
    --auth-page-text-border-bottom-color      : #048a40;

    --select-background  : #EFEFEF;
    --select-color       : #000;
    --select-hover       : #fff;
    --select-border-color: #adb6bb;

    --auth-page-intelbras-normal-icon-color  : #048a40;
    --auth-page-intelbras-emphasis-icon-color: #fff;
    --auth-page-sig-cloud-normal-icon-color  : #048a40;
    --modal-bg-novo                          : #fcfcfc;
    --modal-header-line                      : #707070;
    --modal-x-simbol                         : #fff;
    --modal-x-simbol-hover                   : #d91616;
    --modal-ico-border                       : #d91616;
    --modal-ico-border-hover                 : #d91616;
    --modal-ico-close-bg                     : #d91616;
    --modal-ico-close-bg-hover               : #fff;

    --secondary-color-novo           : #425559;
    --secondary-background-novo      : #cfcfcf;
    --secondary-background-hover-novo: #858585;
    --secondary-color-hover-novo     : #fff;

    --success-background-novo      : #389cff;
    --success-color-novo           : #fff;
    --success-background-hover-novo: #007ffe;

    --table-color                     : #425559;
    --table-header-background         : #fff;
    --table-header-shadow             : #00000029;
    --table-body-border-color         : rgba(112, 112, 112, 0.2);
    --table-row-background-color      : #fff;
    --table-row-background-hover-color: #f0f0f0;
    --table-column-border-color       : rgba(112, 112, 112, 0.2);
    --table-header-column-border-color: #425559;
    --table-header-border             : 1px solid #adb6bb;
    --table-actions-divisor-color     : #425559;
    --table-body-border               : 1px solid #425559;
    --table-button-background-color   : #389cff;
    --subtable-background             : #efefef;

    --success         : #00a335;
    --error           : #ff0009;
    --updating        : #389cff;
    --offline         : #787878;
    --nao-provisionada: #507888;
    --problema-optico : #f3d62f;
    --nao-suportada   : #ff6b26;

    --card-background             : #fff;
    --card-text-color             : #425559;
    --card-border-ok              : 1px solid var(--success);
    --card-border-error           : 1px solid var(--error);
    --card-border-updating        : 1px solid var(--updating);
    --card-border-offline         : 1px solid var(--offline);
    --card-border-nao-provisionada: 1px solid var(--nao-provisionada);
    --card-border-problema-optico : 1px solid var(--problema-optico);
    --card-border-nao-suportada   : 1px solid var(--nao-suportada);
    --card-section-divisor-color  : #425559;
    --card-button-color           : #425559;
    --card-button-border          : #425559;

    --sfp-optical-module-status-bg   : #048A40;
    --sfp-optical-module-status-color: #fff;

    --modal-header-background-color   : #FFF;
    --modal-header-button-text-color  : #FFF;
    --modal-header-button             : rgba(0, 0, 0, 0.4);
    --modal-header-button-hover       : rgba(0, 0, 0, 0.6);
    --modal-header-button-border-color: #ffffff1e;
    --modal-locales                   : rgba(2, 3, 30, 0.06);

    --title-container-background: #425559;

    --svg-input-button-color      : #737380;
    --svg-input-button-hover-color: #425559;

    --input-placeholder-color               : rgba(66, 85, 89, .5);
    --input-border-color                    : rgba(66, 85, 89, .5);
    --input-in-select-svg-color             : #737380;
    --input-in-select-button-hover-svg-color: #425559;
    --input-in-select-border-color          : #adb6bb;
    --input-in-select-text-color            : #000;

    --page-indicator: rgb(223, 223, 223);

    --font-toast-success        : #048A40;
    --font-toast-error          : #ff0009;
    --close-toast-buttom-success: #048A40;
    --close-toast-buttom-error  : #ff0009;

    --alert-hr-color        : #adb6bb;
    --alert-background-color: #EFEFEF 0% 0% no-repeat padding-box;
    --alert-border-color    : #adb6bb;

    --dropdown-svg-color: #425559;

    --olt-informacao-background-color: #FFF;
    --olt-informacao-border-color    : #adb6bb;
    --olt-informacao-box-shadow      : 0px 3px 6px #00000029;
    --olt-informacao-button-color    : #FFF;
    --olt-informacao-divisor-color   : #425559;

    --meus-dados-icon-color: #425559;

    --assinante-servicos-background-color: #FFF;
    --assinante-servicos-border-color    : #adb6bb;
    --assinante-servicos-box-shadow      : 0px 3px 6px #00000029;

    --tooltip-background-color: #fff;
    --tooltip-color           : rgba(0, 0, 0, 0.9);
}