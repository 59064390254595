.modal-search .advancedFilterServices {
    width: 500px;
    position: absolute;
    top: 90px;
    left: 5px;
    z-index: 5;
    border-radius: .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 45px 8px 8px 8px;
    box-shadow: -3px 6px 10px #00000029;
    border: 1px solid rgba(207, 207, 207, 0.678);
    background-color: var(--card-background);
    max-width: 500px;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    min-width: 310px;
}

.modal-search .advancedFilterServices div:not(.div-select-icon) {
    width: 220px;
}

.modal-search .advancedFilterServices div:nth-child(7) {
    display: flex;
    justify-content: flex-end;
    padding-right: 12.5px;
    width: 100%;
    align-content: flex-end;
    margin: 10px 0;
}

.advancedItem {
    display: flex;
    max-width: fit-content;
    align-items: center;
    justify-content: center;
}

.advancedFilterServices .advancedItem:nth-child(odd) {
    padding-right: 2vh;
}

.advancedFilterServices .advancedItem:nth-child(even) {
    padding-left: 2vh;
}

.advancedFilterServices .box-form-select .select-options {
    overflow-x: hidden;
}

.campo-pesquisa .btn.normal.primary {
    text-decoration: none;
    margin-left: 5px;
}

.campo-pesquisa {
    margin-top: 12px;
}

.campo-pesquisa .btn.normal.primary svg {
    fill: white
}

.btnAdvancedFilter {
    display: flex;
    /* Adicionado */
    justify-content: space-between;
    align-items: center;
}

.btnAdvancedFilter .btn.normal.secondary {
    position: relative;
    border-radius: 6px;
    background-color: var(--secondary-background-novo);
    color: var(--secondary-color-novo);
    width: auto;
}

.btnAdvancedFilter .btn.normal.secondary .btn-content {
    justify-content: center;
    width: 73px;
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(1) {
    background-color: var(--secondary-background-novo);
    margin-top: 20px;
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(1) p {
    color: var(--secondary-color-novo);
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(1):hover {
    background-color: var(--secondary-background-hover-novo);
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(1):hover>.btn-content p {
    color: var(--secondary-color-hover-novo)
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(2) {
    background-color: var(--primary-background);
    margin-left: 10px;
    margin-top: 20px;
    color: white;
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(2) p {
    color: white;
}

.btnAdvancedFilter .btn.normal.secondary:nth-child(2):hover {
    background-color: var(--primary-background-hover);
}

.titleAdvancedFilter {
    position: absolute;
    z-index: 6;
    left: 29px;
    top: 107px;
    margin-top: 3px;
    padding: 0 4px 2px 4px;
    width: 450px;
    border-bottom: 1px solid var(--form-background);
    color: var(--fontColors);
    font-weight: 700;
    opacity: 0.90;
    display: flex;
    justify-content: space-between;
}


.titleAdvancedFilter .btn.normal.primary {
    border: transparent;
    background-color: transparent;
}

.titleAdvancedFilter .btn.normal.primary:hover {
    background-color: var(--secondary-background-hover-novo);
    border-radius: 5px;
    width: 90px;
}

.titleAdvancedFilter .btn {
    padding: 0;
}

.titleAdvancedFilter .btn.normal.primary svg {
    height: 20px;
    width: 20px;
}

.titleAdvancedFilter .btn.normal.primary p {
    color: var(--fontColors);
    padding: 0;
}

.titleAdvancedFilter .btn.normal.primary p:hover {
    color: white;
    margin: 0 13px 0 13px;
}

/* Estilo Modal CPE */

.modal-search .advancedFilterCpe {
    width: 500px;
    position: absolute;
    top: 90px;
    left: 5px;
    z-index: 5;
    border-radius: .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 45px 8px 8px 8px;
    box-shadow: -3px 6px 10px #00000029;
    border: 1px solid rgba(207, 207, 207, 0.678);
    background-color: var(--card-background);
    max-width: 500px;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.modal-search .advancedFilterCpe div:nth-child(7) {
    display: flex;
    justify-content: flex-end;
    padding-right: 12.5px;
    width: 100%;
    align-content: flex-end;
    margin: 10px 0;
}

.modal-search .advancedFilterCpe div {
    width: 220px;
}

.advancedItem {
    display: flex;
    max-width: fit-content;
    align-items: center;
    justify-content: center;
}

.advancedFilterCpe .advancedItem:nth-child(odd) {
    padding-right: 2vh;
}

.advancedFilterCpe .advancedItem:nth-child(even) {
    padding-left: 2vh;
}

.advancedFilterCpe .box-form-select .select-options {
    overflow-x: hidden;
}

.advancedFilterCpe .box-form-select .select-options button {
    border-radius: 0px;
}

.advancedFilterCpe .box-form-select .select-options button:hover {
    border-radius: 0px;
    color: white
}


@media (max-width: 728px) {
    .modal-search .advancedFilterServices {
        width: 100%;
        position: absolute;
        top: 80px;
        left: 5px;
        z-index: 5;
        border-radius: .5rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 38px;
        box-shadow: -3px 6px 10px #00000029;
        border: 1px solid rgba(207, 207, 207, 0.678);
        background-color: var(--card-background);
        max-width: 100%;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }

    .titleAdvancedFilter {
        position: absolute;
        z-index: 6;
        left: 24px;
        top: 90px;
        margin-top: 3px;
        padding: 0 0 2px 4px;
        width: 270px;
        border-bottom: 1px solid var(--form-background);
        color: var(--fontColors);
        font-weight: 700;
        opacity: 0.90;
    }

    .advancedFilterServices .advancedItem:nth-child(even) {
        padding-left: 0;
    }

    .advancedFilterServices .advancedItem:nth-child(odd) {
        padding-right: 0;
    }

    .modal-search .advancedFilterServices div:nth-child(5) {
        padding-right: 0;
    }

    /* Estilo Modal CPE*/
    .modal-search .advancedFilterCpe {
        width: 100%;
        position: absolute;
        top: 80px;
        left: 5px;
        z-index: 5;
        border-radius: .5rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 38px;
        box-shadow: -3px 6px 10px #00000029;
        border: 1px solid rgba(207, 207, 207, 0.678);
        background-color: var(--card-background);
        max-width: 100%;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }

    .modal-search .advancedFilterCpe div {
        width: 100%;
    }

    .titleAdvancedFilter {
        position: absolute;
        z-index: 6;
        left: 24px;
        top: 90px;
        margin-top: 3px;
        padding: 0 0 2px 4px;
        width: 270px;
        border-bottom: 1px solid var(--form-background);
        color: var(--fontColors);
        font-weight: 700;
        opacity: 0.90;
    }

    .advancedFilterCpe .advancedItem:nth-child(even) {
        padding-left: 0;
    }

    .advancedFilterCpe .advancedItem:nth-child(odd) {
        padding-right: 0;
    }

    .advancedItem {
        margin-right: 20px;
    }

    .btnAdvancedFilter {
        margin-right: 18px;
    }
}