.card {
    position                : relative;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : flex;
    -webkit-box-orient      : vertical;
    -webkit-box-direction   : normal;
    -ms-flex-direction      : column;
    flex-direction          : column;
    min-width               : 0;
    word-wrap               : break-word;
    overflow-wrap           : break-word;
    overflow                : auto;
    hyphens                 : auto;
    /* background-color     : var(--list-background); */
    margin-bottom           : 20px;
    background-clip         : border-box;
    border                  : var(--card-border);
    border-radius           : 0.25rem;
    box-shadow              : var(--box-shadow);
    height                  : calc(100% - 20px);
    top                     : 0;
    transition              : all .2s ease-in-out;
}

.card-header {
    border-bottom             : 1px solid rgba(30, 30, 30, 0.2);
    display                   : flex;
    flex-direction            : row;
    justify-content           : space-between;
    align-items               : flex-start;
    padding                   : 10px 15px;
    /* background-color       : rgba(30, 30, 30, .5); */
    border-top-left-radius    : .5em;
    border-top-right-radius   : .5em;
    flex                      : 1 1 auto;
}

.card-body {
    display            : flex;
    flex-direction     : column;
    -webkit-box-flex   : 1;
    -ms-flex           : 1 1 auto;
    flex               : 1 1 auto;
    padding            : 10px 15px;
    justify-content    : center;
    align-items        : center;
    /* background-color: rgba(48, 48, 48, .5); */
}

.card.development .card-body p {
    color  : white;
    opacity: 1;
}

.card.development .card-title {
    color  : white;
    opacity: 1;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-title .icon {
    margin-right: 6px;
}

.card-subtitle {
    margin-top   : -0.375rem;
    margin-bottom: 0;
}

.card-text {
    color: var(--list-content-color);
}

.card-text:last-child {
    margin-bottom: 0;
}

.card ol {
    padding-left: 15px;
}

.card li {
    color: var(--list-content-color);
}

.card.development {
    background-color: var(--error-background);
}

.card-buttons {
    display                   : flex;
    justify-content           : space-evenly;
    /* background-color       : rgba(30, 30, 30, .5); */
    padding                   : 7.5px 0px;
    border-bottom-left-radius : .5em;
    border-bottom-right-radius: .5em;
    border-top                : 1px solid rgba(30, 30, 30, 0.2);
}

.title {
    display        : flex;
    flex-direction : row;
    align-items    : flex-start;
    justify-content: flex-start;
}

.card:hover {
    top       : -4px;
    box-shadow: 0 8px 7px rgba(48, 48, 48, .75);
}

#teste3 {
    position        : relative;
    top             : 0;
    padding         : 4px;
    background-color: #cf6;
    transition      : all .2s ease-in-out
}

#teste3:hover {
    top       : -4px;
    box-shadow: 0 4px 3px red;
}

.warning {
    background-color: var(--warning-background);
}