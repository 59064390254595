.dropdownComponent{
    display: flex;
    align-items: center;
}

.menu-left{
    display: flex;
    align-items: center;
}

.menu-right{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.expand-menu.erro{
    border: 1px solid var(--error-background);
}

.expand-menu.development{
    background-color: var(--error-background);
}