.modern-table>* {
    color: var(--table-color);
}

.modern-table {
    /* border        : 1px solid red; */
    display       : flex;
    flex-direction: column;
}

.modern-table .table-header {
    position        : relative;
    border          : var(--table-header-border);
    display         : flex;
    flex-direction  : column;
    background-color: var(--table-header-background);
    border-radius   : 10px;
    box-shadow      : 0px 3px 6px var(--table-header-shadow);
    top             : 10px;
}

.modern-table .table-body {
    /* border        : 1px solid blue; */
    display                   : flex;
    flex-direction            : column;
    border-left               : 1px solid var(--table-body-border-color);
    border-right              : 1px solid var(--table-body-border-color);
    border-bottom             : 1px solid var(--table-body-border-color);
    border-bottom-left-radius : 10px;
    border-bottom-right-radius: 10px;
}

.modern-table .table-header .table-row,
.modern-table .table-body .table-row {
    /* border : 1px solid orange; */
    flex   : 1;
    display: flex;
}

.modern-table .table-body .table-row {
    background-color: var(--table-row-background-color);
}

.modern-table .table-body .table-row:hover {
    background-color: var(--table-row-background-hover-color);
}

.modern-table .table-header .table-column,
.modern-table .table-body .table-column {
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-direction : column;
    flex           : 1 1 auto;
    margin         : 5px 0px;
    width          : 100%;
    padding        : 5px 0px;
}

.modern-table .table-body .table-column.actions {
    flex-direction: row;
}

.box-interface .modern-table .table-body .table-column.actions {
    flex-direction: column;
}

.modern-table .table-body .table-content:not(:last-child) .table-row .table-column {
    border-bottom: 1px solid var(--table-column-border-color);
}

.modern-table .table-body .table-row .table-column {
    margin: 0 0px;
}

.modern-table .table-body .table-content:first-child .table-row.first-item {
    padding-top: 20px;
}

.modern-table .table-body .table-row:last-child .table-column:first-child,
.modern-table .table-body .table-row:last-child .table-column:last-child {
    border-radius: inherit;
}

.modern-table .table-header .table-column:not(:first-child) {
    border-left: 1px solid var(--table-header-column-border-color);
}

.modern-table .table-body .table-column button.btn.secondary {
    margin-top   : 2px;
    margin-bottom: 2px;
}

.modern-table .table-body .table-column button.btn.secondary {
    background-color: var(--table-button-background-color);
    padding         : 2px 10px;
}

.modern-table .table-body .table-column>svg {
    margin-right: 10px;
}

.modern-table .table-body .table-column .btn>svg {
    font-size: 17px;
}

.modern-table .table-body .table-row .table-column .table-column-info-with-icon {
    display    : flex;
    align-items: center;
    min-width  : 100px;
}

.modern-table .table-body .table-row .table-column .table-column-info-with-icon svg {
    margin-right: 10px;
    float       : left;
}

.modern-table .table-body .table-row .table-column hr {
    display: none;
}

.subtable-body .modern-table .table-body .table-row .table-column hr {
    display: initial;
    bottom : 0;
}

.subtable-body .modern-table .table-body .table-row .table-column:first-of-type hr {
    margin-left: 60px;
}

.modern-table .table-body .table-content {
    transition: background-color 0.2s ease-in-out;
}

.modern-table .table-body .table-content.focus {
    background-color: var(--subtable-background);
    overflow-y      : auto;
}

.modern-table .table-body .table-content.focus .table-row {
    background-color: var(--subtable-background);
    transition      : background-color 0.2s linear;
}

.modern-table .table-body .table-content.focus .table-row .table-column {
    border-bottom: var(--subtable-background);
    transition   : border-bottom 0.2s linear;
}

.subtable-body .modern-table .table-header .table-column:not(:first-child) {
    border-left: 0px;
}

.subtable-body .modern-table .table-header {
    border: 1px solid var(--subtable-background);
}

.subtable-body .modern-table .table-body {
    border: 0px;
}

.modern-table .table-body .table-column.actions button {
    padding: 0px;
}

.table-column.actions hr {
    margin-top  : -5px;
    margin-right: 60px;
    bottom      : 0;
}

.modern-table .table-body .table-column .table-item {
    display       : flex;
    width         : 50%;
    justify-self  : flex-start;
    flex-direction: row;
    align-items   : center;
    text-align    : center;
    position      : relative;
}


.modern-table>* {
    min-width: 400px;
}

.modern-table .table-body .table-column .table-item {
    min-width: 80px;
}

.modern-table .table-body .table-column .table-item div {
    display        : flex;
    position       : absolute;
    justify-content: center;
    width          : 100%;
}


.modern-table .table-body .table-column.actions button svg {
    max-height: 1.1em;
    margin-top: -10px;
}

.modern-table .table-body .table-content .table-row.pointer {
    cursor: pointer;
}

.modern-table .table-body .table-row .table-column .vlan-dropdown {
    stroke    : var(--table-color);
    transform : rotate(-90deg);
    transition: transform 0.2s linear;
}

.modern-table .table-body .table-row .table-column .vlan-dropdown>* {
    stroke-width: 2.5;
}

.modern-table .table-body .table-content.focus .table-row .table-column .vlan-dropdown {
    transform: rotate(0deg);
}

.subtable-body {
    transition: max-height 0.2s linear;
}

.subtable-body.opened {
    height    : 400px;
    max-height: 400px;
    overflow-x: hidden;
}

.subtable-body.closed {
    max-height: 0px;
    overflow  : hidden;
}

.subtable-body .loader-container {
    position        : relative;
    z-index         : 0;
    height          : 400px;
    width           : 110%;
    background-color: var(--subtable-background);
}

.subtable-body .modern-table .table-body .table-content:not(:last-child) .table-row .table-column {
    padding: 0px;
}

.table-divisor {
    position        : relative;
    top             : -5px;
    height          : 70%;
    width           : 1px;
    background-color: var(--table-actions-divisor-color);
}