.list-item {
    padding-top: 8px;
    padding-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: start;
}

.topic {
    left: 15px;
}

/* .borda-test {
    border: 2px solid red;
} */

.list-topic {
    padding-bottom: 20px;
}

.list-topic:not(:last-child) {
    margin-bottom: 2vh;
}

.list-topic .list-item:not(:last-child) {
    border-bottom: 1px solid var(--modal-header-line);
}


.list-topic>.list-item:first-of-type {
    margin-top: 0.2vh;
}

.list-topic>div>div:first-child {
    text-align: left;
}

.list-topic>div>div:last-child {
    display: flex;
    text-align: right;
    justify-content: flex-end;

}

.list-topic-item {
    width: 100%;
}

.list-topic .list-item .list-topic-item .online-box {
    width: fit-content;
    border-radius: 5px;
    border: 1.5px solid var(--sfp-optical-module-status-bg);
    background-color: var(--sfp-optical-module-status-bg);
    color: var(--sfp-optical-module-status-color);
    font-size: 13px;
    font-weight: 750;
    padding-left: 0.3vh;
    padding-right: 0.3vh;
}