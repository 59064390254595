.box-olt {
    position: relative;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.protocol-menu {
    display: flex;
    flex-direction: row;
}

.protocol-menu button {
    background: none;
    border: none;
    color: #306430;
    font-size: 1.5rem;
    margin-left: .5rem;
    margin-right: .5rem;
}

div[name="snmpProtocol"],
div[name="sshProtocol"],
div[name="telnetProtocol"] {
    flex-direction: column;
}

.tagged {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.olt-informacao h2 {
    padding-bottom: 15px;
}

.box-conteudo div {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0.3em 0 0.3em;
}

@media (max-width: 550px) {
    .box-conteudo div {
        /* ; */
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0.3em 0 0.3em;
    }

    .box-conteudo p {
        margin: 5px 0 5px 0;
    }

    .box-conteudo Button {
        margin-top: 10px;
    }
}

@media (max-width: 410px) {
    .box-conteudo div {
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0.3em 0 0.3em;
    }

    .box-conteudo Button {
        margin: 0px;
    }
}

.loader {
    border: 3px solid var(--modal-color);
    border-radius: 50%;
    border-top: 3px solid var(--modal-header-line);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    /* margin-left: 10px; */
    margin-left: auto;
}

#spanFabricantes {
    font-weight: bold;
    font-size: 1.1em;
}

#spanRadioButtom {
    display: table;
    margin-left: 0;
    margin-top: 5px;
}

.testeClass {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    border: 1px solid black;
    width: 50%;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#progress-vlan {
    border: 1px solid var(--modal-header-line);
    padding: 10px 5px 10px 5px;
    margin-top: 10px;
    display: inline-table;
    background-color: var(--menu-background);
    width: 300px;
}

.change-alert {
    width: 100%;
    height: 100%;
    border: 1px solid var(--alert-border-color);
    background: var(--alert-background-color);
    border-radius: 10px;
    padding: 12px 20px 0px 20px;
    max-height: 245px;
    overflow: auto;
}

.box-conteudo-criar-editar-olt .row {
    transition: all 2s linear 10s;
}

.box-conteudo-criar-editar-olt .hide {
    height: 0;
}

.box-conteudo-criar-editar-olt .row.show .change-alert .alert-message {
    display: flex;
}

.box-conteudo-criar-editar-olt .row.show .change-alert hr {
    padding: 0;
    border: none;
    background-color: var(--alert-hr-color);
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
}

.box-conteudo-criar-editar-olt .row.show .change-alert .alert-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-conteudo-criar-editar-olt .row.show .change-alert .alert-icon {
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-conteudo-criar-editar-olt .row.show .change-alert p {
    text-align: justify;
}

.box-conteudo-criar-editar-olt .row.show .change-alert span {
    text-align: justify;
    font-weight: bold;
}

.box-conteudo-criar-editar-olt .row.show .change-alert .uplinkConfig span:not(.uplinkCheck p span) {
    opacity: 1;
}

.box-conteudo-criar-editar-olt .row.show .change-alert .alert-icon svg {
    width: 20px;
    height: 20px;
}

.row.show .menu-left button .btn-content .icon {
    fill: var(--dropdown-svg-color);
}

.downloadModalAdapter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
}

.downloadModalAdapter p {
    text-align: center;
}

.downloadModalAdapter>span {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border: 1px solid var(--form-background);
    height: 40px;
    background-color: var(--form-background);
    margin: 0 10px 0 20px;
}

.box-conteudo-criar-editar-olt .row.show .change-alert input {
    width: 90%;
    margin-left: 36px;
}

.box-conteudo-criar-editar-olt .row.show .change-alert #input-vlan {
    width: 90%;
    margin-left: 10px;
}

/* .vlanOlt {
    width: 90%;
    margin-left: 36px;
} */

.uplinkConfig {
    display: flex;
    flex-direction: row;
}

.uplinkConfig p:nth-child(2n) {
    color: var(--error);
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid var(--alert-border-color);
    padding: 5px;
    margin: -4px 0 -5px 0;
}

.uplinkCheck {
    width: 180%;
    padding-right: 15px;
}