* {
    font-family: Nunito;
}

.cartao {
    position               : relative;
    display                : flex;
    flex-direction         : column;
    justify-content        : center;
    align-items            : center;
    margin                 : 8px 0px;
    background-color       : var(--card-background);
    width                  : 100%;
    /*     min-width       : 320px; */
    border-radius          : .5rem;
    box-shadow             : 0 8px 7px rgba(0, 0, 0, .25);
    height                 : calc(100% - 20px);
}

.cartao.ok {
    border     : var(--card-border-ok);
    border-left: 10px solid var(--success);
}

.cartao.error {
    border     : var(--card-border-error);
    border-left: 10px solid var(--error);
}

.cartao.updating {
    border     : var(--card-border-updating);
    border-left: 10px solid var(--updating);
}

.cartao.offline {
    border     : var(--card-border-offline);
    border-left: 10px solid var(--offline);
}
.cartao.adapter-nao-conectado {
    border     : var(--card-border-offline);
    border-left: 10px solid var(--offline);
}

.cartao.nao-provisionada {
    border     : var(--card-border-nao-provisionada);
    border-left: 10px solid var(--nao-provisionada);
}

.cartao.problema-optico {
    border     : var(--card-border-problema-optico);
    border-left: 10px solid var(--problema-optico);

}

.cartao.nao-suportada {
    border     : var(--card-border-nao-suportada);
    border-left: 10px solid var(--nao-suportada);
}

.cartao.problema-optico button svg {
    fill: var(--problema-optico);
}

.cartao.nao-suportada button svg {
    fill: var(--nao-suportada);
}

.cartao.delete {
    border     : var(--card-border-offline);
    border-left: 10px solid var(--offline);
    opacity    : 0.4;
}

.cartao .cartao-cabecalho p span{
    font-size : 16px;
    margin-top: 5px;
    
}

.cartao p {
    color        : var(--card-text-color);
    padding-left : 10px;
    padding-right: 3px;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.cartao-cabecalho>div>p>.tooltip {
    visibility      : hidden;
    width           : fit-content;
    max-width       : 250px;
    background-color: rgb(0, 0, 0);
    color           : #fff;
    text-align      : center;
    padding         : 4px 4px;
    border-radius   : 6px;
    position        : absolute;
    z-index         : 9;
    top             : 27px;
    left            : 5%;

}

.cartao .cartao-cabecalho p span:nth-child(2){
    overflow-wrap: break-word;
}

.cartao-cabecalho>div>p:hover .tooltip {
    visibility: visible;
}

.cartao p>.bold-text {
    font-weight: 800;
}
.cartao p>.bold-text-descricao {
    font-weight: 800;
    word-break: break-all;
}

.cartao .bold-text.ok {
    color: var(--success);
}

.cartao .bold-text.error {
    color: var(--error);
}

.cartao .bold-text.updating {
    color: var(--updating);
}

.cartao .bold-text.offline {
    color: var(--offline);
}
.cartao .bold-text.adapter-nao-conectado {
    color: var(--offline);
}

.cartao .bold-text.nao-provisionada {
    color: var(--nao-provisionada);
}

.cartao .bold-text.problema-optico {
    color: var(--problema-optico);
}

.cartao .bold-text.nao-suportada {
    color: var(--nao-suportada);
}

.cartao .cartao-cabecalho {
    width  : 100%;
    padding: 10px;
}

.cartao .cartao-cabecalho button {
    margin-right: 5px;
}

.cartao .cartao-corpo {
    padding: 10px;
    width  : 100%;
    flex   : 1;
}

.cartao .cartao-rodape {
    display       : flex;
    width         : 100%;
    padding-bottom: 10px;
    margin-top: auto;
    /* flex          : 1 1 auto; */
}

.cartao .cartao-progresso {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    width          : 100%;
    height         : 100%;
    flex           : 1 1 auto;
    padding        : 0 19px 17px 19px;
    transition     : opacity 0.4s;
}

.cartao .cartao-progresso p {
    margin-left  : -9px;
    margin-bottom: 2px;
}

.section-divisor {
    margin-top         : 6px;
    width              : 99%;
    height             : 1px;
    /* border          : 1px solid #848689; */
    background-color   : var(--card-section-divisor-color);
    opacity            : 0.5;
}

.vertical-section-divisor {
    height          : auto;
    width           : 1px;
    background-color: var(--card-button-border);
    opacity         : 1;
}