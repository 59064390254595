.releaseNotes {
    width       : 100%;
    padding-left: 0;
    max-height  : inherit;
    overflow-y  : auto;
}

.releaseNotes h1 {
    padding-top   : 5px;
    padding-bottom: 5px;
    font-size     : 25px;

    background-color: rgba(128, 128, 128, 0.089);
    padding-left    : 10px;

}

.releaseNotes h2 {
    padding-top   : 5px;
    padding-bottom: 5px;

    font-size: 20px;

    background-color: rgba(128, 128, 128, 0.089);

    padding-left: 10px;
}

.releaseNotes ul {
    list-style : disc;
    margin-left: 20px;
}

.releaseNotes li {
    padding-top   : 10px;
    padding-bottom: 10px;
}