.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1300px;
    padding-top: 20px;
    margin: 0 auto;
}

.block-dashboard {
    background-color: var(--title-container-background);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    flex: 1;
    max-width: 350px;
    position: relative;
}

.container-pie-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.block-olt {
    background-color: var(--title-container-background);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    flex: 2;
    max-width: 500px;
    position: relative;
}

.block-big {
    background-color: var(--title-container-background);
    padding: 20px;
    border-radius: 8px;
    flex-basis: 100%;
}

.header-dashboard-block {
    display: table;
    margin: 0 auto;
    width: fit-content;
    padding-bottom: 15px;
}

.column-dashboard {
    display: flex;
    flex-direction: column;
    /* Ajustando para column */
}

.content-dashboard-block {
    display: flex;
    flex-direction: column;
}

.donut-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
}

.content-dashboard-block span {
    margin-top: 5px;
    display: block;
    margin-bottom: 10px;
    max-width: 220px;
    overflow-wrap: break-word;
}

.title-dashboard-item {
    font-size: medium;
    font-weight: bold;
}

.title-dashboard-item-online {
    font-size: medium;
    font-weight: bold;
    color: var(--success);
}

.footer-dashboard-block {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.footer-dashboard-block span {
    display: inline-block;
}

@media (max-width: 768px) {
    .dashboard-container {
        flex-direction: column;
    }

    .block-big {
        flex-basis: 100%;
    }

    .donut-chart-container {
        flex-direction: column;
        align-items: flex-start;
    }
}