.loader-container {
    position        : absolute;
    top             : 0;
    width           : calc(100% - 15px);
    height          : 110%;
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: var(--page-background);
    margin          : -4vh;
    z-index         : 999;
}

.loader-container img {
    opacity: 0.8;
}

.loader-container.show {
    /* display: flex; */
    opacity: 1;
}

.loader-container.hide {
    /* display: none; */
    opacity: 0;
}


/* Testes */

.lds-ring {
    display   : inline-block;
    position  : relative;
    align-self: center;
    width     : 80px;
    height    : 80px;
}

.lds-ring div {
    box-sizing   : border-box;
    display      : block;
    position     : absolute;
    width        : 64px;
    height       : 64px;
    margin       : 8px;
    border       : 8px solid #ACACAC;
    border-radius: 50%;
    animation    : lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color : #ACACAC transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}