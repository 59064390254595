.background-grey {
  padding-top: 20px;
  padding-bottom: 10px;

  border-left: 1px grey solid;
  background-color: rgba(128, 128, 128, 0.089);

  display: flex;
  align-items: center;
  justify-content: start;
}

.label-modal {
  padding-right: 10px;
}

.box-cpe .modal-content .section-divisor {
  margin-top: 0px;
}

.box-cpe .list-topic {
  padding-bottom: 0px;
}

.list-topic .list-item:nth-child(11),
.col-md-12.descricao {
  position: relative;
}

.list-topic .list-item #defaultBtnEditar {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 25px;
  right: 1vh;
}

#defaultBtnEditar svg {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 3px;
  right: 4px;
}

.col-md-12.descricao #defaultBtnEditar{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 17px;
  right: 23px;
}

#btnAtualizarInfoCPE{
  text-overflow: ellipsis;
}