.box-notificacoes {
    position: relative;
}

.notificacoes-cards {
    display   : flex;
    flex-wrap : wrap;
    margin-top: 15px;
}

.alerta-notificacao {
    background-color: var(--content-background);
    border          : 2px solid var(--content-background);
    border-radius   : 5px;
    display         : inline-block;
    padding         : 10px;
    margin          : 0 5px 10px 5px;
    position        : relative;
    width           : calc((100% - 30px) / 3);
    min-width       : 250px;
    box-shadow      : var(--box-shadow);
}

.alerta-notificacao .conteudo {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    max-width      : 100%;
}

@media(max-width: 800px) {
    .alerta-notificacao .conteudo .button {
        display       : flex;
        flex-direction: column;
        margin-bottom : 5px;
    }

    .alerta-notificacao .btn.circle {
        margin-left  : 5px;
        margin-bottom: 5px;
    }
}

.alerta-notificacao .btn.circle {
    margin-left: 5px;
}

.alerta-notificacao .break {
    flex-basis: 100%;
    width     : 0;
}

.alerta-notificacao small,
p {
    color  : var(--fontColors);
    display: block;
}

.box-container>footer>p {
    color  : var(--primary-color);
    display: block;
}

.alerta-notificacao.error {
    border-color: var(--error-background);
}

.alerta-notificacao.warning {
    border-color: var(--warning-background-hover);
}

.notificacao-erro small {
    display: block;
}

.notificacao-erro p {
    margin-top: 10px;
}