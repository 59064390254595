#root {
    font-family: Nunito;
}

#login-bg {
    background: transparent url("../../assets/auth-background3.jpg") 0% 0% no-repeat padding-box;
    background-size: cover;
}

.botoes {
    display: flex;
    margin: 10px 0px;
    justify-content: center;
}

.botoes>*:not(:first-child) {
    margin-left: 15px;
}

.filho1 {
    float: right;
}

.filho2 {

    float: right;
    padding-right: 20px;
}

.locButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.locButtons #campo-ok {
    margin-right: 20px;
    padding: 8px 20px;
}

.locButtons #add-nova-localizacao {
    margin-right: -5px;
}

.locButtons #add-nova-localizacao svg {
    font-size: 1.6em;
    fill: var(--secondary-color);
}

.warning-div {
    position: absolute;
    margin-bottom: 450px;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #ed6c02;
    font-size: 14px;
    width: fit-content;
    text-align: justify;
}

.warning-div span {
    color: white;
}

/*
.box-flutuante {
  background: var(--page-login-background);
  color: var(--page-color);
  height: 100vh;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.box-flutuante > h1 {
  line-height: 50px;
  letter-spacing: -1.5px;
}

.box-flutuante-conteudo {
  background: var(--content-background);
  padding: 14px 15px 15px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14);
  width: 100%;
  max-width: 450px;
}
.box-flutuante-conteudo form {
  position: relative;
}

.box-flutuante-conteudo h3 {
  text-align: center;
}
.box-flutuante-conteudo .box-idioma, .box-options {
  width: 130px;
  position: fixed;
  right: 10px;
  top: 5px;
}
.box-flutuante-conteudo .box-tema {
  width: 130px;
  position: fixed;
  right: 10px;
  top: 60px;
}

.box-acoes-grupo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.seleciona {
  display: inline;
}
.botoes {
  float: right;
  padding-right: 15px;
  margin-top: 10px;
}

.filho1 {
  float : right;
}
.filho2{
  
  float: right;
  padding-right : 20px;
}
.locButtons{
  padding-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;

}

.col-md-9{
  padding-right: 0px;
}

.col-md-3{
  padding-left: 0px;
}

.show-feedback{
  display: block;

}
.hide-feedback{
  display: none;

}

.scroll{
  height: 190px;
}

.esqueceu-senha{
  cursor: pointer;
  color: var(--primary-background);
  text-decoration: underline;
  display: block;
  margin-top: 10px;
  width: fit-content;
}

.esqueceu-senha:hover{
  color: var(--primary-background-hover);
}
 */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: var(--auth-page-container-background);
    transition: background-color .1s ease-in;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 80%;
    /* border         : 1px solid black; */
    border-radius: 15px;
    position: relative;
    background-color: var(--auth-page-background);
}

.auth-container .content::before {
    content: "";
    width: 40%;
    height: 100%;
    background-color: var(--auth-page-before-background);
    position: absolute;
    right: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.first-column h2,
.first-column h3,
.first-column h4,
.first-column h5,
.first-column h6,
.first-column p {
    margin-bottom: 10px;
    color: var(--auth-page-first-column-color);
    opacity: 1;
}

.first-column p {
    font-weight: 600;
    line-height: .5;
}

.second-column h2,
.second-column h3,
.second-column h4,
.second-column h5,
.second-column h6 {
    margin-bottom: 10px;
    color: var(--auth-page-second-column-color);
    opacity: 1;
}

.second-column a {
    margin-top: 20px;
    color: var(--auth-page-link-color);
    font-weight: bold;
    cursor: pointer;
}

.second-column .form .box-form input,
.second-column .form .box-form textarea {
    border: none;
    border-bottom: 1.5px solid var(--auth-page-text-border-bottom-color);
    border-radius: 0px;
    min-width: 300px;
}

.second-column .form .box-form.erro input,
.second-column .form .box-form.erro textarea {
    border-color: var(--error-background);
}

.second-column .form .box-form input::placeholder {
    color: var(--placeholder-color);
}

.second-column .form .box-form input:focus::placeholder {
    color: transparent;
}

.second-column .form .box-form input:-webkit-autofill {
    -webkit-text-fill-color: var(--placeholder-color) !important;
}

.auth-container .content .btn {
    border-radius: 10px;
    color: var(--auth-page-btn-color);
    font-size: 13px;
    height: 33.2px;
    cursor: pointer;
    font-weight: bold;
    width: 150px;
    align-self: center;
    border: none;
    margin-top: 1rem;
    border: 1px solid var(--auth-page-btn-border);
    background-color: var(--auth-page-btn-background);
    box-shadow: 0px 3px 6px var(--auth-page-btn-box-shadow-color);
}

.auth-container .content .btn:hover {
    background-color: var(--auth-page-btn-hover-background);
    color: var(--auth-page-btn-second-column-hover-color);
}

.auth-recover-container .content .btn:hover {
    background-color: var(--auth-page-btn-hover-background);
    color: var(--auth-page-btn-second-column-hover-color);
}

.first-column .btn:hover {
    border-color: var(--auth-page-btn-first-column-hover-border);
}

.second-column .btn:hover {
    border-color: var(--auth-page-btn-second-column-hover-border);
}




.content.first-content .first-column {
    order: 2;
}

.content.first-content .second-column {
    display: flex;
    height: 100%;
    order: 1;
}

.content.second-content .first-column,
.content.second-content .second-column {
    z-index: -1;
}

.content .content-header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    /* border         : 1px solid red; */
}

.content .content-header .configs {
    width: 40%;
    /* border         : 1px solid yellow; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.content .content-header .configs .language-config {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
}

.content .content-header .configs .theme-config {
    width: 45%;
}

.second-column .form .box-form>label {
    color: var(--auth-page-link-color);
}

.second-column .form .box-form input {
    color: var(--auth-page-link-color);
}

/* .second-column .form .box-form svg {
    fill: var(--auth-page-sig-cloud-normal-icon-color);
} */

.second-column .form .box-form>button:hover {
    background-color: inherit;
}

.second-column .form .box-form #btnLimpar:hover {
    color: var(--form-background-hover);
    background-color: var(--content-color);
}

.second-column .form .box-form #btnLimpar:hover svg {
    fill: var(--svg-input-button-hover-color);
    stroke: var(--svg-input-button-hover-color);
}

.first-column,
.second-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-column {
    width: 40%;
    z-index: 4;
}

.second-column {
    width: 60%;
    z-index: 1;
}

/* Testes */

.first-content {
    position: absolute;
}

.second-content {
    position: absolute;
}

.form>svg {
    margin-bottom: 15px;
    stroke: var(--auth-page-sig-cloud-normal-icon-color);
    fill: var(--auth-page-sig-cloud-normal-icon-color);
}

.form button>svg {
    fill: var(--auth-page-sig-cloud-normal-icon-color);
}

.content-header .logo>svg {
    margin-left: 4vh;
    margin-top: 4vh;
    fill: var(--auth-page-intelbras-normal-icon-color);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .5));
    height: 35px;
    width: 165px;
}

.sign-up-js .content-header .logo>svg {
    margin-left: 4vh;
    margin-top: 4vh;
    fill: var(--auth-page-intelbras-emphasis-icon-color);
    transition: fill 1.3s ease-in-out;
    width: 165px;
    height: 35px
        /* animation  : fadeIn 2.6s; */
}

.sign-in-js .content-header .logo>svg {
    transition: fill 0.5s ease-out;
    width: 165px;
    height: 35px
}

.second-column .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Para animações ao trocar entre registrar e login */


.sign-up-js .auth-container .content::before {
    right: 60%;
    border-top-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
    animation: slideToLeft 1.3s;
    z-index: 3;
}

.sign-up-js .content.first-content .second-column {
    position: relative;
    animation: fadeOutToRight 1.3s;
    z-index: -1;
}

.sign-up-js .content.first-content .first-column {
    z-index: -1;
}

.sign-up-js .content.second-content .first-column {
    z-index: 4;
}

.sign-up-js .content.second-content .second-column {
    z-index: 1;
}

.sign-in-js .auth-container .content::before {
    right: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 15px;
    animation: slideToRight 1.3s;
    z-index: 3;
}

.sign-in-js .content.second-content .second-column {
    position: relative;
    animation: fadeOutToLeft 1.3s;
    z-index: -1;
}

/* .sign-up-js .content.second-content .first-column .btn,
.sign-in-js .content.first-content .first-column .btn {
    animation: fadeIn 2.6s;
    opacity  : 1;
} */

.sign-up-js .content.second-content .first-column,
.sign-in-js .content.first-content .first-column {
    animation: fadeIn 2.6s;
    opacity: 1;
}

.hintAdapter {
    text-decoration: underline;
    position: relative;
    margin-right: 5px;
}

.createLocationText p span {
    opacity: 1;
}

.createLocationText .hintAdapter .tooltip {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 4px 0;
    border-radius: 6px;


    position: absolute;
    z-index: 9;
    top: 18px;
    left: 0%;
}

.createLocationText .hintAdapter:hover .tooltip {
    visibility: visible;
}

.createLocationText {
    width: 100%;
    margin-left: 2px;
}

@keyframes slideToLeft {
    from {
        right: 0;
    }

    to {
        right: 60%;
    }
}

@keyframes slideToRight {
    from {
        right: 60%;
    }

    to {
        right: 0;
    }
}

@keyframes fadeOutToRight {
    from {
        right: 0;
        z-index: 2;
        opacity: 1;
    }

    25% {
        right: -80px;
        opacity: .5;
    }

    50% {
        right: -100px;
        opacity: .2;
    }

    to {
        right: -110px;
        z-index: -1;
        opacity: 0;
    }
}

@keyframes fadeOutToLeft {
    from {
        right: 0;
        z-index: 2;
        opacity: 1;
    }

    25% {
        right: 80px;
        opacity: .5;
    }

    50% {
        right: 100px;
        opacity: .2;
    }

    to {
        right: 110px;
        z-index: -1;
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Versão mobile */
@media screen and (max-width: 1040px) {
    .content {
        width: 100%;
        height: 100%;
    }

    .auth-container .content::before {
        width: 100%;
        height: 40%;
        bottom: 0;
        border-radius: 0;
    }

    .auth-container .content.second-content .first-column {
        margin-top: 30%;
    }


    .first-content,
    .second-content {
        flex-direction: column;
        justify-content: space-around;
    }

    .first-column,
    .second-column {
        width: 100%;
    }

    .sign-up-js .auth-container .content::before {
        bottom: 60%;
        left: 0;
        border-radius: 0;
        animation: slideToLeft 1.3s linear;
    }

    .sign-in-js .auth-container .content::before {
        bottom: 0;
        left: 0;
        border-radius: 0;
        animation: slideToRight 1.3s linear;
    }

    .form {
        width: 90%;
    }

    /* ANIMAÇÃO MOBILE CSS*/

    @keyframes fadeOutToRight {

        from {
            bottom: 0;
            opacity: 1;
            z-index: 12;
        }

        25% {
            bottom: -80px;
            opacity: .5;
        }

        50% {
            bottom: -100px;
            opacity: .2;
        }

        to {
            bottom: -110px;
            opacity: 0;
            z-index: -1;
        }
    }


    @keyframes fadeOutToLeft {

        from {
            bottom: 0;
            opacity: 1;
            z-index: 12;
        }

        25% {
            bottom: 80px;
            opacity: .5;
        }

        50% {
            bottom: 100px;
            opacity: .2;
        }

        to {
            bottom: 110px;
            z-index: -1;
            opacity: 0;
        }
    }

    @keyframes slideToLeft {
        from {
            bottom: 0;
            height: 40%;
        }

        to {
            bottom: 60%;
            height: 40%;
        }
    }

    @keyframes slideToRight {
        from {
            bottom: 60%;
            height: 40%;
        }

        to {
            bottom: 0;
            height: 40%;
        }
    }
}

@media screen and (max-width: 740px) {
    .second-column .form {
        width: 50%;
        height: 85%;
    }
}

@media screen and (max-width: 425px) {
    .form {
        width: 100%;
    }
}

.box-meus-dados {
    border: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.dados-usuario {
    border: 1px solid #707070;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 161px;
    padding: 27.67px 0px;
    border-radius: 10px;
}

.perfil {
    padding-top: 134px;
    height: 0px;

}

.info-usuario {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.info-usuario svg {
    width: 4rem;
    height: 4rem;
}

.dados-usuario .info-usuario p {
    margin-top: 10px;
}

.seguranca-usuario {
    border: 1px solid #707070;
    display: flex;
    border-radius: 10px;
    justify-content: space-evenly;
    width: 100%;
    height: 236.08px;
    padding: 5px 50px;
    flex-wrap: wrap;
}

.box-senha {
    display: flex;
    width: 311px;
    height: 45px;
    top: 725px;
    left: 594px;
}

.botoes {
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 10px;
}

div.col-md-12.botoes .btn {
    height: fit-content;
    min-width: 120px;
    padding: 0.75vh 2vh;
    border: none;
}

.info {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1366px) {
    .seguranca-usuario {
        padding: 5px 25px;
    }
}

.dados-usuario svg path {
    fill: var(--meus-dados-icon-color);
}

.show-feedback {
    display: block;

}

.hide-feedback {
    display: none;

}

.passwordValidation {
    display: flex;
    width: 100%;
    height: 25px;
    margin-top: -4px;
    justify-content: space-between;
    flex-wrap: wrap;
    transition: opacity 0.3s, height 0.5s;
    flex-direction: row;
}

.hidePassValidation {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, height 0.5s;
}

.passwordValidation svg {
    width: 20px;
    height: 100%;
    margin-top: -4px;
    margin-right: 11px;
    transition: opacity 0.3s, width 0.5s 0.5s;
}

.passwordValidation .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 4px 0;
    border-radius: 6px;
    margin-top: -5px;
    width: 180px;

    position: absolute;
    z-index: 999;
}

.infoPassword .tooltip::after {
    content: "";
    position: absolute;
    top: 10%;
    right: 100%;
    margin-top: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent rgb(0, 0, 0) transparent transparent;
}

.infoPassword:hover .tooltip {
    visibility: visible;
}

.passwordValidation .strongPassword {
    display: flex;
    border-top: 4px solid var(--success-background);
    width: 100%;
    font-size: 13px;
    justify-content: center;
    transition: opacity 0.3s, width 0.3s 0.5s;
}

.passwordValidation .mediumPassword {
    display: flex;
    justify-content: center;
    border-top: 4px solid var(--warning-background);
    width: 75%;
    font-size: 13px;
    transition: opacity 0.3s, width 0.3s 0.5s;
}

.passwordValidation .weakPassword {
    display: flex;
    justify-content: center;
    border-top: 4px solid var(--error-background);
    width: 35%;
    font-size: 13px;
    transition: opacity 0.3s, width 0.3s 0.5s;
}

/*Tela Meus Dados */

.seguranca-usuario .passwordValidation {
    display: flex;
    width: 48%;
    height: 25px;
    margin-top: -2px;
    justify-content: space-between;
    flex-wrap: wrap;
    transition: opacity 0.3s, height 0.5s;
    flex-direction: row;
    align-self: flex-end;
    margin-left: -36vh;

}

.seguranca-usuario .passwordValidation p {
    white-space: nowrap;
}

.seguranca-usuario .passwordValidation svg {
    margin-left: -5vh;
}

.seguranca-usuario .passwordValidation .strongPassword {
    width: 90%;
}