.custom-control-radio {
  position: relative;
}
.custom-control-radio > label {
  display: block;
  margin-bottom: 5px;
  line-height: 14px;
  font-size: 14px;
}
.custom-control-radio .radio-inline {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}
.custom-control-radio .radio-inline:last-child {
  margin-right: 0px;
}
.custom-control-radio input[type='radio'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-radio input[type='radio'] + label {
  cursor: pointer;
  line-height: 20px;
}
.custom-control-radio input[type='radio'] + label:before {
  content: '';
  width: 15px;
  height: 15px;
  border: 2px solid;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 50%;
  float: left;
}
/* .custom-control-radio input[type="radio"]:checked + label:before {
  background-color: #36c456;
  box-shadow: inset 0px 0px 2px 1px #393939;
} */

.custom-control-radio input[type='radio']:checked + label:after {
  content: '';
  background-color: #36c456;
  width: 10px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 4px;
}
.custom-control-radio input:disabled + label {
  opacity: 0.65;
}

.invalid-feedback{
  margin-top: 10px;
}

.show-error-hint{
  border: 1px solid var(--error-background);
  border-radius: 10px;
  padding: 10px;
}
.custom-label {
  color: #979696;
  font-size: 12px !important;
}