/* .borda{

    border: 2px solid red;
}
 */

.margem{

    margin-bottom: 30px;
}

.canto-direito{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}


/* .alarme-container{
    display: flex;
    justify-content:space-between;
} */