* {
    margin    : 0;
    padding   : 0;
    outline   : 0;
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
}

#root {
    position        : fixed;
    width           : 100%;
    background-color: var(--page-background);
}

/* * {
    font-family: 'Nunito', sans-serif;
} */

textarea,
body,
input,
button,
a {
    resize: none;
    font  : 14px Nunito, sans-serif;
}

a,
span,
td {
    opacity: 0.90;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--fontColors) !important;
    transition             : background-color 5000s ease-in-out 0s;
}

hr {
    /* border     : 0;
    border-top : 1px solid var(--hr-color);
    box-sizing : content-box;
    height     : 0;
    overflow   : visible;
    padding-top: 10px;
    margin-top : 10px; */
    padding         : 0;
    border          : none;
    background-color: var(--alert-hr-color);
    height          : 1px;
    width           : 100%;
    margin-top      : 10px;
    margin-bottom   : 5px;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size  : 34px;
    line-height: 36px;
}

h3 {
    font-size     : 24px;
    line-height   : 24px;
    letter-spacing: 0.18px;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size     : 20px;
    line-height   : 24px;
    letter-spacing: 0.15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    color: var(--fontColors);
}

small {
    font-size     : 14px;
    line-height   : 20px;
    color         : var(--fontColors);
    letter-spacing: 0.25px;
    opacity       : 0.6;
}

ul {
    list-style-type: none;
}

button {
    cursor: pointer;
}

.flex-start {
    display        : flex;
    align-items    : center;
    justify-content: flex-start;
}

.flex-end {
    display        : flex;
    align-items    : center;
    justify-content: flex-end;

}

.flex-end>button:nth-child(n+2) {
    margin-left: 5px;
}

::-webkit-scrollbar {
    width : 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--scroll-background);
}

::-webkit-scrollbar-thumb {
    background-color: var(--scroll-track);
    border-radius   : 10px;
}

::-webkit-scrollbar-thumb:hover {
    cursor          : pointer;
    background-color: var(--scroll-track-hover);
}

*:disabled {
    cursor: not-allowed;
}

::-ms-reveal {
    display: none;
}


/* Estilo Toast Erro */

.Toastify__toast--error {
    background-color: var(--card-background);
    border-left     : 38px solid var(--error);
    border-top      : 1px solid var(--error);
    border-right    : 1px solid var(--error);
    border-bottom   : 2px solid var(--error);
    color           : var(--font-toast-error);
    font-weight     : 700;
}

.Toastify__progress-bar--error {
    background-color: var(--error);
    margin-left     : 14px;
    width           : 86%;
    border          : 2px solid var(--error);
    height          : 5.5px;
    bottom          : 3.5px;
    border-radius   : 4px;
}


.Toastify__toast {
    font-size    : 13px;
    border-radius: 8px;
}


.Toastify__toast-icon {
    width            : 0 !important;
    margin-inline-end: 0 !important;
}


/* Estilo Toast success */

.Toastify__toast--success {
    background-color: var(--card-background);
    border-left     : 38px solid #048A40;
    border-top      : 1px solid #048A40;
    border-right    : 1px solid #048A40;
    border-bottom   : 2px solid #048A40;
    color           : var(--font-toast-success);
    font-weight     : 700;
}

.Toastify__toast--success>svg {
    fill: #048A40;
}

.Toastify__progress-bar--success {
    background-color: #048A40;
    margin-left     : 14px;
    width           : 86%;
    border          : 2px solid #048A40;
    height          : 5.5px;
    bottom          : 3.5px;
    border-radius   : 4px;
}


/* Botão fechar toast */

.Toastify__toast--error #fecharToast div svg {
    position     : absolute;
    width        : 18px;
    height       : 18px;
    border-radius: 50%;
    border       : 1px solid red;
    fill         : var(--close-toast-buttom-error) !important;
    margin       : -25px -0.4px;
    z-index      : 99999;
}

.Toastify__toast--error #fecharToast div svg:hover {
    position        : absolute;
    width           : 18px;
    height          : 18px;
    border-radius   : 50%;
    background-color: red;
    fill            : white !important;
    margin          : -25px -0.4px;
    z-index         : 99999;
}

.Toastify__toast--success #fecharToast div svg {
    position     : absolute;
    width        : 18px;
    height       : 18px;
    border-radius: 50%;
    border       : 1px solid #048A40;
    fill         : var(--close-toast-buttom-success) !important;
    margin       : -25px -0.4px;
    z-index      : 99999;
}

.Toastify__toast--success #fecharToast div svg:hover {
    position        : absolute;
    width           : 18px;
    height          : 18px;
    border-radius   : 50%;
    background-color: #048A40;
    fill            : white !important;
    margin          : -25px -0.4px;
    z-index         : 99999;
}


/* Estilo icones toast */

.divIconToastSuccess {
    display        : flex;
    justify-content: center;
    align-items    : center;
    position       : fixed;
    border-radius  : 10px 0px 0px 10px;
    margin         : -15px 0 0 -57.5px;
    width          : 50px;
    height         : 30px;
    z-index        : 999999;
}

.divIconToastSuccess>svg {
    fill: white !important;
}

.divIconToastError {
    display        : flex;
    justify-content: center;
    align-items    : center;
    position       : fixed;
    border-radius  : 10px 0px 0px 10px;
    margin         : -15px 0 0 -57.5px;
    width          : 50px;
    height         : 30px;
    z-index        : 999999;
}

.divIconToastError>svg {
    fill: white !important;
}

.qntRegisters {
    margin-top : -8px;
    margin-left: 10px;
}

.boldText {
    font-weight: bold;
}

.divisorLine {
    width           : 99.2%;
    background-color: var(--card-section-divisor-color);
    height          : 1px;
    margin-bottom   : 8px;
    margin-top      : 6px;
    margin-left     : 7px;
    opacity         : 0.5;
}

.Toastify__zoom-enter {
    animation-name: none;
}

.Toastify--animate-icon {
    animation-fill-mode: none;
    animation-duration : 0s
}