.box-servicos-assinante {
    padding   : 10px 15px;
    min-height: calc(100% - 37px);
    position  : relative;
}

.box-servicos-lista {
    background   : var(--list-background);
    border-radius: 4px;
    margin-bottom: 10px;
    padding      : 10px;
}

.box-servicos-lista button {
    margin-left: 5px;
}

.box-servicos-lista h2 {
    margin-bottom: 15px;
}

.box-servicos-lista p {
    margin-bottom: 10px;
}

.box-servicos-assinante .box-conteudo div label,
.box-servicos-assinante .box-conteudo div p {
    opacity: 0.9;
    color  : var(--menu-color);
}