.containerGeral {
    background-color    : var(--container-background);
    align-self          : center;
    width               : 100%;
    height              : 100%;
    overflow            : auto;
    /*  border-radius   : 20px; */
    border-radius       : 0px 10px 10px 0px;
    box-shadow          : 5px 2px 10px rgb(0 0 0 / 30%);
    padding             : 4vh;
}

.containerLocal {
    display         : inline-flex;
    justify-content : center;
    background-color: var(--title-container-background);
    width           : fit-content;
    padding         : 1vh 2vh 1vh 2vh;
    border-radius   : 0px 0px 10px 10px;
    margin-left     : -3vh;
    box-shadow      : 0px 3px 3.5px rgb(0 0 0 / 25%);
}
.status-adapter{
    /* padding          : 1vh 1vh 1vh 1vh; */
    margin-top: 6px;
    padding: 0.6vh 0.8vh 0.6vh 0.8vh;
    margin-bottom: 6px;
    margin-left: 4px;
    border-radius   : 2vh;
    width           : fit-content;
}
.align-left-side{
    margin-top      : -4vh;
    margin-bottom   : 3vh;
    display: flex;
}

.containerLocal>p {
    font-size   : 1.3em;
    padding-left: 10px;
    color       : #fff;
}
.status-message{
    font-size   : 1em;
    font-weight: 700;   
    fill  : #fff;
}

.containerLocal>svg {
    margin: -0.5vh 0.1vh 0.2vh 0.1vh;
    width : 15px;
}