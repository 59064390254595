.box-interface {
    position: relative;
}

.background-grey {
    padding-top     : 10px;
    padding-bottom  : 10px;
    border-left     : 1px grey solid;
    background-color: rgba(128, 128, 128, 0.089);
    display         : flex;
    align-items     : center;
    justify-content : start;
}

.card-interface {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    min-width     : 600px;
    z-index       : 1;
    padding       : 0 40px;
}

.selectInterfaceMode {
    width: 200px;
}

.card-interface h5 {
    font-size   : 1.2em;
    margin-right: 30px;
}

.vlan-title {
    margin-top : 15px;
    margin-left: 30px;
}

.label-modal {
    padding-right: 10px;
}

.flex-max {
    flex: 1 1 200px;
}

.btn-interface {
    z-index     : 1;
    margin-top  : 2.5vh;
    margin-right: 25px;
}

.subtable-body .col-md-12 {
    padding-left : 0;
    padding-right: 0;
}