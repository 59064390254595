.box-cpe {
    position: relative;
}

.expand {
    background-color: darkslategrey;
    border-radius   : 0.5em;
}

.expand-menu {
    border          : 1.5px solid var(--select-border-color);
    display         : flex;
    flex-direction  : row;
    padding         : 0 0;
    margin-bottom   : 15px;
    width           : 100%;
    background-color: var(--select-background);
    border-radius   : 10px;
}

.menu-separator {
    border-top     : 1px solid var(--secondary-background);
    margin-top     : -20px;
    margin-left    : 30px;
    border-collapse: collapse;
    margin-bottom  : 25px;
}

.menu-title {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    font-size      : 16px;
    margin-left    : 10px;
}

.menu-button {
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-size      : 24px;
    border         : 1px solid white;
    border-radius  : 100%;
    cursor         : pointer;
}

.menu-button:hover {
    background-color: white;
    color           : darkslategrey;
}

/* .expand-body{
    overflow: hidden;
    max-height: 750px;
    transition: max-height .5s ease-in-out;
}

.show-expand-body{
    overflow: hidden;
    max-height: fit-content;
    transition: max-height .5s ease-in-out;
}

.hide-expand-body{
    max-height: 0;
} */

.show-expand-body {
    display: flex;
}

.hide-expand-body {
    display: none;
}