.div-select {
    margin-top: 2vh;
    position  : relative;
    width     : 100%;
}

.div-select * {
    -webkit-touch-callout: none;
    -webkit-user-select  : none;
    -khtml-user-select   : none;
    -moz-user-select     : none;
    -ms-user-select      : none;
    user-select          : none;
    cursor               : pointer;
    color                : var(--select-color);
}

.div-select:hover {
    cursor: pointer;
}

.div-selected {
    border          : 1px solid var(--select-background);
    display         : flex;
    background-color: var(--select-background);
    border-radius   : .5rem;
    padding         : 10px;
    justify-content : space-between;
}

.div-select-identification {
    display    : flex;
    align-items: center;
}

.div-select-options {
    padding         : 7.5px 0px;
    max-height      : 150px;
    width           : 100%;
    position        : absolute;
    background-color: var(--select-background);
    margin-top      : 10px;
    border-radius   : .5rem;
    transition      : all .1s ease-in;
    z-index         : 2;
    overflow        : hidden;
}

.div-select-options.show {
    display   : block;
    /* border : 1px solid var(--select-background); */
}

.div-select-options.hidden {
    padding   : 0px 0px;
    max-height: 0px;
}

.div-select-option {
    padding         : 10px;
    background-color: inherit;
    min-height      : 42px;
}

.div-select-option:hover {
    background-color: var(--select-hover);
}

.div-select-icon {
    display    : flex;
    align-items: center;
    transition : all .2s linear;
}

.div-select-identification div+label {
    margin: 0px 10px;
}

.div-select-identification label {
    margin-right: 10px;
}

.div-select-option-identification-img {
    width : 22px;
    height: 22px;
}

.div-select-options::-webkit-scrollbar {
    background: transparent;
}

.div-select-options::-webkit-scrollbar-track-piece,
.div-select-options::-webkit-scrollbar-track {
    display: none;
}

.div-select-icon.rotate {
    transform: rotate(180deg);
}

.div-select-icon svg {
    stroke : var(--select-color);
    opacity: 0.9;
}