.box-form-select>button:not(:disabled) * {
    /* color : var(--select-color); */
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.box-form-select>button:disabled * {
    cursor: not-allowed;
}

.box-form-select {
    position        : relative;
    margin-bottom   : 10px;
    padding-top     : 10px;
    width           : 100%;
    /* margin-right : 22px; */
}

.box-form-select>button {
    opacity            : 1.9;
    min-height         : 42px;
    background-color   : var(--select-background);
    border             : 1.5px solid var(--select-border-color);
    border-radius      : 10px;
    color              : var(--select-color);
    display            : block;
    /* padding         : 9px 15px 6px 15px; */
    padding            : 10px;
    text-align         : left;
    transition         : border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width              : 100%;
    max-width          : 100%;
    display            : flex;
    justify-content    : space-between;
    align-items        : center;
}

.box-form-select>button:disabled {
    opacity: 0.5;
}

/* .box-form-select>button:after {
    content      : '';
    border       : 7px solid transparent;
    position     : absolute;
    right        : 10px;
    top          : 25px;
    border-top   : 7px solid currentColor;
    border-bottom: 0;
} */

.box-form-select.open>button:after {
    transform: rotate(180deg);
}

.box-form-select>button>label {

    color           : var(--form-color-hover);
    /* cursor       : auto; */
    position        : absolute;
    border-radius   : 2px;
    top             : -10px;
    left            : 10px;
    margin          : 0;
    padding         : 0 5px;
    transition      : top 0.15s ease-in-out, font-size 0.15s ease-in-out;
}

.box-form-select>button>label sup {
    font-size: 0%;
}

.box-form-select .select-options {
    margin-top      : 10px;
    background-color: var(--select-background);
    border-radius   : 10px;
    position        : absolute;
    z-index         : 2;
    padding         : 7.5px 0px;
    width           : 100%;
    max-height      : 300px;
    overflow        : auto;
    transition      : all .1s ease-in;
    border          : 1px solid var(--select-border-color);
}

.box-form-select .div-select-icon {
    display    : flex;
    align-items: center;
    transition : all .2s linear;
}

.box-form-select .div-select-identification {
    display    : flex;
    align-items: center;
    min-height : 22px;
}

.box-form-select .select-options.show {
    display: block;
}

.box-form-select .select-options.hidden {
    padding   : 0px 0px;
    max-height: 0px;
    border    : transparent;
}

.box-form-select .select-actions {
    margin: 5px 10px;
}

.box-form-select .select-actions button {
    display: inline-block;
    width  : 49%;
}

.box-form-select .select-actions button:first-child {
    margin-right: 2%;
}

.box-form-select .select-search {
    display: flex;
    margin : 5px 10px;
}

.box-form-select .select-search>div {
    align-items: center;
    display    : flex;
    position   : relative;
}

.box-form-select .select-search>div.box-form {
    width          : 100%;
    /* margin-right: 10px; */
}

.box-form-select .select-option {
    /* border-top: 1px solid var(--form-background); */
    color           : var(--select-color);
    /* cursor          : pointer; */
    padding         : 10px 10px;
    margin          : 0;
    width           : 100%;
    display         : block;
    position        : relative;
    /*     font-size: 1.2em; */
}

.box-form-select .select-option:hover,
.box-form-select .select-option.selected {
    background-color       : var(--select-hover);
    /*     color           : var(--select-box-color); */
}

.box-form-select.multiselect .select-option {
    padding: 0;
}

.box-form-select.multiselect .select-option input {
    display: none;
}

.box-form-select.multiselect .select-option label {
    /* cursor   : pointer; */
    padding  : 6px 10px;
    margin   : 0;
    width    : 100%;
    display  : block;
    position : relative;
    font-size: 1.2em;
}

.box-form-select.multiselect .select-option input:checked+label {
    background-color: transparent;
    color           : var(--content-color);
}

/* .box-form-select.multiselect .select-option input+label::before {
    display      : inline-flex;
    content      : '';
    color        : var(--content-color);
    border       : 2px solid;
    border-radius: 5px;
    height       : 13px;
    width        : 13px;
    margin-right : 5px;
    opacity      : 0.5;
} */

.box-form-select.multiselect .select-option input:checked+label::before {
    background-color: var(--primary-background);
}

.box-form-select.multiselect .select-option input:checked+label::after {
    display      : inline-block;
    content      : '';
    color        : var(--content-color);
    border-bottom: 2px solid;
    border-left  : 2px solid;
    height       : 4px;
    width        : 8px;
    transform    : rotate(-45deg);
    position     : absolute;
    top          : 13px;
    left         : 13px;
}

.box-form-select .select-option * {
    color: var(--select-color);
}

.box-form-select.erro button,
.box-form-select.erro textarea {
    border-color: var(--error-background);
}

.box-form-select button>.div-select-icon svg {
    stroke : var(--select-color);
    opacity: 0.9;
}

.box-form-select .div-select-option-identification-img {
    width : 22px;
    height: 22px;
}

.box-form-select .div-select-identification div+label {
    margin: 0px 10px;
}

.box-form-select .select-options::-webkit-scrollbar-track {
    /* width: 0; */
    background   : var(--select-background);
    border-radius: 10px;
}

.box-form-select #btnLoadMore {
    text-align: center;
}

.box-form-select .box-form input {
    border: 1px solid var(--input-in-select-border-color);
    color : var(--input-in-select-text-color);
}

.box-form-select .box-form button>svg {
    fill: var(--input-in-select-svg-color);
}

.box-form-select .box-form>button:nth-child(3):hover {
    background-color: var(--input-in-select-svg-color);
}

.box-form-select .box-form>button:nth-child(3):hover svg {
    fill  : #FFF;
    stroke: #FFF;
}