/*  .btn {
     background-color: transparent;
     border          : 1px solid transparent;
     border-radius   : 4px;
     color           : var(--color-page);
     cursor          : pointer;
     display         : inline-block;
     font-size       : 1em;
     padding         : 8px 15px;
     transition      : all 0.15s;
 }

 .btn.circle {
     align-items    : center;
     border-radius  : 50px;
     display        : inline-flex;
     font-size      : 1em;
     justify-content: center;
     min-height     : 34px;
     min-width      : 34px;
     padding        : 6px;
 }

 .btn.block {
     width: 100%;
 }

 .btn:active {
     position: relative;
     top     : 1px;
 }

 .btn:focus {
     outline: none;
 }

 .btn:disabled {
     opacity: 0.3;
 }

 .btn>svg {
     font-size: 20px;
 }

 .btn.normal.primary {
     background-color: var(--primary-background);
     border-color    : var(--primary-background-hover);
     color           : var(--primary-color);
 }

 .btn.normal.secondary {
     background-color: var(--buttonBackground);
     border-color    : var(--button-border);
     color           : var(--secondary-color);
 }

 .btn.normal.transparent {
     background-color: var(--transparent-background);
     color           : var(--transparent-color);
 }

 .btn.normal.success {
     background-color: var(--success-background);
     border-color    : var(--success-background-hover);
     color           : var(--success-color);
 }

 .btn.normal.warning {
     background-color: var(--warning-background);
     border-color    : var(--warning-background-hover);
     color           : var(--warning-color);
 }

 .btn.normal.danger {
     background-color: var(--error-background);
     border-color    : var(--error-background-hover);
     color           : var(--error-color);
 }

 .btn.normal.primary:hover {
     background-color: var(--primary-background-hover);
 }

 .btn.normal.secondary:hover {
     background-color: var(--secondary-background-hover);
 }

 .btn.normal.transparent:hover {
     background-color: var(--transparent-background-hover);
 }

 .btn.normal.success:hover {
     background-color: var(--success-background-hover);
 }

 .btn.normal.warning:hover {
     background-color: var(--warning-background-hover);
 }

 .btn.normal.danger:hover {
     background-color: var(--error-background-hover);
 }

 .btn.outline.primary {
     border-color: var(--primary-background);
     color       : var(--color-page);
 }

 .btn.outline.secondary {
     border-color: var(--secondary-background);
     color       : var(--color-page);
 }

 .btn.outline.success {
     border-color: var(--success-background);
     color       : var(--color-page);
 }

 .btn.outline.warning {
     border-color: var(--warning-background);
     color       : var(--color-page);
 }

 .btn.outline.danger {
     border-color: var(--error-background);
     color       : var(--color-page);
 }

 .btn.outline.primary:hover {
     background-color: var(--primary-background);
     color           : var(--primary-color);
 }

 .btn.outline.secondary:hover {
     background-color: var(--secondary-background);
     color           : var(--secondary-color);
 }

 .btn.outline.success:hover {
     background-color: var(--success-background);
     color           : var(--success-color);
 }

 .btn.outline.warning:hover {
     background-color: var(--warning-background);
     color           : var(--warning-color);
 }

 .btn.outline.danger:hover {
     background-color: var(--error-background);
     color           : var(--error-color);
 }

 .btn.outlined.primary {
     border-color: var(--primary-background);
     color       : var(--primary-background);
 }

 .btn.outlined.secondary {
     border-color: var(--secondary-background);
     color       : var(--secondary-background);
 }

 .btn.outlined.success {
     border-color: var(--success-background);
     color       : var(--success-background);
 }

 .btn.outlined.warning {
     border-color: var(--warning-background);
     color       : var(--warning-background);
 }

 .btn.outlined.danger {
     border-color: var(--error-background);
     color       : var(--error-background);
 }

 .btn.outlined.primary:hover {
     background-color: var(--primary-background);
     color           : var(--primary-color);
 }

 .btn.outlined.secondary:hover {
     background-color: var(--secondary-background);
     color           : var(--secondary-color);
 }

 .btn.outlined.success:hover {
     background-color: var(--success-background);
     color           : var(--success-color);
 }

 .btn.outlined.warning:hover {
     background-color: var(--warning-background);
     color           : var(--warning-color);
 }

 .btn.outlined.danger:hover {
     background-color: var(--error-background);
     color           : var(--error-color);
 }

 .link {
     background-color   : transparent;
     border             : 0px solid transparent;
     border-bottom-width: 1px;
     color              : var(--color-page);
     cursor             : pointer;
     display            : inline-block;
     font-size          : 1em;
 }

 .link:active {
     position: relative;
     top     : 1px;
 }

 .link:focus {
     outline: none;
 }

 .link.normal.primary {
     color: var(--primary-background);
 }

 .link.normal.secondary {
     color: var(--secondary-background);
 }

 .link.normal.success {
     color: var(--success-background);
 }

 .link.normal.warning {
     color: var(--warning-background);
 }

 .link.normal.danger {
     color: var(--error-background);
 }

 .link.normal:hover {
     opacity: 0.7;
 }

 .link.outlined {
     color: var(--color-page);
 }

 .link.outlined.primary:hover {
     border-color: var(--primary-background);
 }

 .link.outlined.secondary:hover {
     border-color: var(--secondary-background);
 }

 .link.outlined.success:hover {
     border-color: var(--success-background);
 }

 .link.outlined.warning:hover {
     border-color: var(--warning-background);
 }

 .link.outlined.danger:hover {
     border-color: var(--error-background);
 } */




/*
 NOVO LAYOUT
 */
.btn {
    background-color: transparent;
    border          : 1px solid transparent;
    border-radius   : 10px;
    color           : var(--color-page);
    cursor          : pointer;
    display         : inline-flex;
    align-items     : center;
    justify-content : center;
    font-size       : 1em;
    font-weight     : 600;
    padding         : 8px 15px;
    transition      : all 0.15s;
}

.btn.modal {
    width  : 120px;
    padding: 0.75vh 2vh;
}

.btn.circle {
    align-items    : center;
    border-radius  : 50px;
    display        : inline-flex;
    font-size      : 1em;
    justify-content: center;
    min-height     : 34px;
    min-width      : 34px;
    padding        : 6px;
}

.btn.block {
    width: 100%;
}

.btn:active {
    position: relative;
    top     : 1px;
}

.btn:focus {
    outline: none;
}

.btn:focus-visible {
    border: 2.5px solid black;
}

.btn:disabled {
    opacity: 0.3;
}

.btn .btn-content .btn-border {

    border          : 2px solid white;
    background-color: white;
}

.btn .btn-content .btn-icon {
    display      : inherit;
    border-radius: 50%;
}

.btn .btn-content>svg {
    font-size: 20px;
}

.btn .btn-content p+.btn-icon {
    margin-right: 5px;
}

.btn .btn-content .btn-icon>svg {
    font-size: 20px;
    fill     : var(--success-background);
}

.btn-content {
    display    : inherit;
    align-items: center;
    white-space: nowrap;
}

.btn-content p {
    font-weight: 600;
    padding    : 3px 8px 3px 10px;
    color      : white;
}


.btn.normal.primary {
    background-color: var(--primary-background);
    border-color    : var(--primary-background-hover);
    color           : var(--primary-color);
}

.btn.normal.secondary {
    background-color: var(--buttonBackground);
    border-color    : var(--button-border);
    color           : var(--primary-color);
}

.btn.modal.secondary {
    background-color: var(--secondary-background-novo);
    color           : var(--secondary-color-novo);
}


.btn.normal.transparent {
    background-color: var(--transparent-background);
    color           : var(--transparent-color);
}

.btn.normal.success {
    background-color: var(--success-background);
    color           : var(--success-color);
}

.btn.modal.success {
    background-color: var(--success-background-novo);
    color           : var(--success-color-novo);
}

.btn.normal.warning {
    background-color: var(--warning-background);
    border-color    : var(--warning-background-hover);
    color           : var(--warning-color);
}

.btn.normal.danger {
    background-color: var(--error-background);
    border-color    : var(--error-background-hover);
    color           : var(--error-color);
}

.btn.normal.primary:hover {
    background-color: var(--primary-background-hover);
}

.btn.normal.secondary:hover {
    background-color: var(--secondary-background-hover);
    color           : var(--secondary-color-hover);
}

.btn.modal.secondary:hover {
    background-color: var(--secondary-background-hover-novo);
    color           : var(--secondary-color-hover-novo);
}

.btn.normal.transparent:hover {
    background-color: var(--transparent-background-hover);
}

.btn.normal.success:hover {
    background-color: var(--success-background-hover);
}


.btn.modal.success:hover {
    background-color: var(--success-background-hover-novo);
}


.btn.normal.warning:hover {
    background-color: var(--warning-background-hover);
}

.btn.normal.danger:hover {
    background-color: var(--error-background-hover);
}

.btn.outline.primary {
    border-color: var(--primary-background);
    color       : var(--color-page);
}

.btn.outline.secondary {
    border-color: var(--secondary-background);
    color       : var(--color-page);
}

.btn.outline.success {
    border-color: var(--success-background);
    color       : var(--color-page);
}

.btn.outline.warning {
    border-color: var(--warning-background);
    color       : var(--color-page);
}

.btn.outline.danger {
    border-color: var(--error-background);
    color       : var(--color-page);
}

.btn.outline.primary:hover {
    background-color: var(--primary-background);
    color           : var(--primary-color);
}

.btn.outline.secondary:hover {
    background-color: var(--secondary-background);
    color           : var(--secondary-color);
}

.btn.outline.success:hover {
    background-color: var(--success-background);
    color           : var(--success-color);
}

.btn.outline.warning:hover {
    background-color: var(--warning-background);
    color           : var(--warning-color);
}

.btn.outline.danger:hover {
    background-color: var(--error-background);
    color           : var(--error-color);
}

.btn.outlined.primary {
    border-color: var(--primary-background);
    color       : var(--primary-background);
}

.btn.outlined.secondary {
    border-color: var(--secondary-background);
    color       : var(--secondary-background);
}

.btn.outlined.success {
    border-color: var(--success-background);
    color       : var(--success-background);
}

.btn.outlined.warning {
    border-color: var(--warning-background);
    color       : var(--warning-background);
}

.btn.outlined.danger {
    border-color: var(--error-background);
    color       : var(--error-background);
}

.btn.outlined.primary:hover {
    background-color: var(--primary-background);
    color           : var(--primary-color);
}

.btn.outlined.secondary:hover {
    background-color: var(--secondary-background);
    color           : var(--secondary-color);
}

.btn.outlined.success:hover {
    background-color: var(--success-background);
    color           : var(--success-color);
}

.btn.outlined.warning:hover {
    background-color: var(--warning-background);
    color           : var(--warning-color);
}

.btn.outlined.danger:hover {
    background-color: var(--error-background);
    color           : var(--error-color);
}

.link {
    background-color   : transparent;
    border             : 0px solid transparent;
    border-bottom-width: 1px;
    color              : var(--color-page);
    cursor             : pointer;
    display            : inline-block;
    font-size          : 1em;
}

.link:active {
    position: relative;
    top     : 1px;
}

.link:focus {
    outline: none;
}

.link.normal.primary {
    color: var(--primary-background);
}

.link.normal.secondary {
    color: var(--secondary-background);
}

.link.normal.success {
    color: var(--success-background);
}

.link.normal.warning {
    color: var(--warning-background);
}

.link.normal.danger {
    color: var(--error-background);
}

.link.normal:hover {
    opacity: 0.7;
}

.link.outlined {
    color: var(--color-page);
}

.link.outlined.primary:hover {
    border-color: var(--primary-background);
}

.link.outlined.secondary:hover {
    border-color: var(--secondary-background);
}

.link.outlined.success:hover {
    border-color: var(--success-background);
}

.link.outlined.warning:hover {
    border-color: var(--warning-background);
}

.link.outlined.danger:hover {
    border-color: var(--error-background);
}