.box-menu-layout {
    display                  : flex;
    position                 : relative;
    background-color         : var(--sidebar-background);
    border-top-left-radius   : 10px;
    border-bottom-left-radius: 10px;
    flex-direction           : column;
    overflow                 : hidden;
    z-index                  : 2;
    height                   : 100%;
    justify-content          : center;

    opacity   : 0.98;
    box-shadow: -8px 0px 17px -1px rgba(0, 0, 0, 0.22);
    transition: width 0.5s ease-in-out, min-width 0.5s ease-in-out;

    width        : 70px;
    min-width    : 70px;
    overflow-wrap: none;
    padding-left : 0.5vw;
}

.menu-item p {
    margin-left: -1.0vh;
    transition : margin-left 0.5s ease-in-out;
}

.menu-item p.opened {
    transition-delay: 0.5s;
}


p.closed {
    margin-left: 5vh;
}

.box-menu-layout.opened {
    width    : 180px;
    min-width: 180px;
}



.box-menu-layout li a {
    color                    : var(--menu-color);
    border-top-left-radius   : 10px;
    border-bottom-left-radius: 10px;
    text-decoration          : none;
    font-weight              : 700;
    letter-spacing           : 0.2px;
    display                  : flex;
    padding                  : 3px;
    height                   : 50px;
}

.box-menu-layout.opened li a {
    animation                : altera-menu-contrario;
    animation-duration       : 1s;
    animation-direction      : normal;
    animation-timing-function: cubic-bezier(0.80, 0.99, 0, 1);

}

.box-menu-layout.opened {
    transition-delay: 0.5s;
}

.box-menu-layout.closed li a {
    animation                : altera-menu;
    animation-duration       : 1s;
    animation-timing-function: cubic-bezier(1, -0.06, .3, 1.11);
    animation-direction      : normal;
    height                   : 70px;

}

@keyframes altera-menu {
    from {
        height: 50px;
    }

    to {
        height: 70px;
    }
}

@keyframes altera-menu-contrario {
    from {
        height: 70px;
    }

    to {
        height: 50px;
    }
}


.box-menu-layout li.opened a:hover {
    background         : linear-gradient(to right, var(--sidebar-background) 50%, var(--sidebar-background-hover) 50%) left;
    background-size    : 200%;
    background-position: right;
    transition         : background-position 0.3s ease-out;
    -webkit-box-shadow : inset 2px 1px 2px 0px rgba(0, 0, 0, 0.5);
    box-shadow         : inset 2px 1px 2px 0px rgba(0, 0, 0, 0.5);
}


.box-menu-layout li.active.opened {
    -webkit-box-shadow       : inset 2px 1px 2px 0px rgba(0, 0, 0, 0.5);
    box-shadow               : inset 2px 1px 2px 0px rgba(0, 0, 0, 0.5);
    background-position      : center;
    background-size          : 100%;
    background               : var(--sidebar-background-hover);
    border-top-left-radius   : 10px;
    border-bottom-left-radius: 10px;
    flex-direction           : row;
}

.menu-item {
    width         : fit-content;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.borda-menu {

    transition: background-color 5s;
}

.menu-scroll-line>*:first-child {
    float : left;
    height: 100%;
    width : 4px;
}

.menu-scroll-line>* {
    overflow     : hidden;
    overflow-wrap: none;
}

.scroll-line {
    background-color         : var(--secondary-color);
    border-top-left-radius   : 0.3vh;
    border-bottom-left-radius: 0.3vh;
    height                   : calc(70%/5);
    transition               : transform 0.5s ease, opacity 0.3s ease;
}

.menuIcon>svg rect {
    fill: var(--page-background);
}

@media screen and (max-width: 500px) {

    .box-menu-layout.opened {
        margin-left     : 0vh;
        position        : absolute;
        display         : flex;
        background-color: var(--sidebar-background-mobile);

        border-top-left-radius   : 15px;
        border-bottom-left-radius: 15px;
        height                   : 100%;
        flex-direction           : column;
        overflow                 : hidden;
        margin-top               : 0vh;
        justify-content          : flex-start;
        z-index                  : 8;
    }

    .box-app>* {
        padding: 0;
    }

    .box-menu-layout.closed {
        display: none;
    }
}