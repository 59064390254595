.download {
    margin-top: 15px;
}

.download .download-message {
    margin-bottom: 15px;
}

.download .download-message p {
    text-align: center;
}

.download .download-buttons {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.download .download-buttons button {
    width: 90px;
}

.download .download-buttons>button:nth-child(2) {
    margin-left: 5px;
}