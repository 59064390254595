.div-new-button {
    top            : 19.5px;
    display        : flex;
    flex-direction : row;
    align-items    : flex-start;
    justify-content: flex-end;
}

.div-new-button>button:nth-child(n+2) {
    margin-left: 5px;
}

.show {
    display: flex;
}

.col-md-12.show {
    display: block;
}

.hide {
    display: none;
}

.div-filters-button {
    top            : 10px;
    display        : flex;
    flex-direction : row;
    align-items    : flex-start;
    justify-content: flex-start;
}

.div-filters-button button {
    background-color: transparent;
    border          : none;
    color           : white;
    text-decoration : underline;
}

.campo-pesquisa .box-form input,
.campo-pesquisa .box-form textarea {
    min-height: 45px;
}

.campo-pesquisa .box-form>button:first-child {
    top: 16px;
}

.campo-pesquisa .box-form #btnLimpar {
    top: 20px;
}

@keyframes slidein {
    from {
        height: 0%;
    }

    to {
        height: 100%;
    }
}