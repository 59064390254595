.box-form {
    position         : relative;
    /* margin-bottom : 10px; */
    padding-top      : 10px;
    display          : flex;
    flex-direction   : column;
}

.box-form>label {
    opacity            : 0;
    /* background-color: var(--content-background); */
    background-color   : inherit;
    color              : var(--content-background);
    cursor             : auto;
    position           : absolute;
    border-radius      : 2px;
    top                : 20px;
    left               : 30px;
    margin             : 0;
    padding            : 0 5px;
    transition         : top 0.15s ease-in-out, font-size 0.15s ease-in-out, left 0.15s ease-in-out;
}

.box-form.disabled.box-form.disabled {
    opacity: 0.5;
}

.box-form.filled>label {
    color        : var(--content-color-hover);
    border-radius: 2px;
    font-size    : 95%;
    top          : -5px;
    left         : 0;
    opacity      : 1;
}

.box-form>label sup {
    font-size: 70%;
}

.box-form .invalid-feedback {
    height       : 0px;
    /* padding   : 4px; */
    color        : var(--auth-page-link-color);
    transition   : height .1s linear;
    display      : flex;
    align-items  : flex-start;
}

.box-form .invalid-feedback.show-feedback {
    height: 20px;
}

.feedback .invalid-feedback {
    padding: 4px;
}

.box-form>button {
    display        : flex;
    align-items    : center;
    justify-content: center;
    position       : absolute;
    top            : 15.5px;
    right          : 10px;
    padding        : 5px;
    border         : 0;
    border-radius  : 50%;
    background     : transparent;
    color          : var(--form-color);
    z-index        : 1;
}

.box-form>button:not(:first-child):hover {
    color           : var(--form-background-hover);
    background-color: var(--content-color);
}

/* .box-form input:focus+label,
.box-form input[value='a']+label,
.box-form textarea:focus+label,
.box-form textarea[value='a']+label {
    border-radius: 2px;
    color        : var(--placeholder-color);
    font-size    : 95%;
    top          : -5px;
    left         : 0;
    opacity      : 1;
} */

.box-form input,
.box-form textarea {
    opacity               : 0.9;
    min-height            : 38px;
    /* background-color   : var(--content-background); */
    background-color      : inherit;
    border                : 1.5px solid var(--input-border-color);
    border-radius         : 10px;
    color                 : var(--content-color-hover);
    display               : block;
    padding               : 9px 15px 6px 15px;
    transition            : border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width                 : 100%;
    max-width             : 100%;
}

.box-form textarea {
    min-height: 90px;
}

.box-form.erro input,
.box-form.erro textarea {
    border-color: var(--error-background);
}

.show {
    /* display: block; */
    visibility: visible;
}

.hide {
    /* display: none; */
    visibility: hidden;
}

/* Chrome, Safari, Edge, Opera */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin            : 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

/* input:focus::-webkit-input-placeholder {
    color: transparent;
} */

@keyframes floatToTopLeft {
    from {
        left: 30px;
        top : -5px;
    }

    to {
        left  : 0;
        bottom: 15px;
    }
}