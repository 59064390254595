/* CSS do Modal de Notificações */
.notificationModal {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 77%;
    padding: 1vh 1vh 0 1vh;
    overflow: scroll;
}

.notificationModal span svg {
    height: 25px;
    width: 25px;
    fill: var(--secondary-color);
    margin-bottom: 3px;
}
#spanLimparNotificacao{
    color: var(--table-header-column-border-color);
    padding-top: 10px;
    margin-bottom: 1px;
    font-weight: 600;
    align-self: flex-end;
}
#spanLimparNotificacao:hover{
    text-decoration: underline;
    cursor: pointer;
}

.notificationModal span {
    display: flex;
    align-items: center;
    flex-direction: column;
    right: 0;
    left: 0;
    align-self: center;
    margin-top: -15px;
    margin-bottom: 12px;
}

.notificationBox {
    display: flex;
    width: 100%;
    flex-basis: 50px;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    align-items: stretch;
    overflow: hidden;
    margin-bottom: 8px;
}

.notificationBox .iconeTexto-notificacao {
    background-color: var(--modal-header-button);
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid var(--modal-header-button-border-color);
    padding: 0.5vh;
    flex-grow: 1;
    font-size: 14px;
    font-weight: bolder;
    overflow: hidden;
    transition: all 0.15s;
}

.notificationBox .iconeTexto-notificacao:hover {
    background-color: var(--modal-header-button-hover);
    color: white;
}


.notificationBox .icone-notificacao {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5vh;
}

.icone-notificacao svg {
    height: 37px;
    width: 23px;
    filter:brightness(100)
    
}

.notificationBox .conteudo-notificacao {
    background-color: aliceblue;
    flex-grow: 1;
}

.notificationBox .texto-notificacao {
    font-size: 0.75rem;
    overflow: auto;
    max-height: 100%;
}

/* width */
.texto-notificacao::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.texto-notificacao::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.texto-notificacao::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.texto-notificacao::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.notificationBox .deletar-notificacao {
    height: 50px;
    width: 50px;
    background-color: var(--error-background);
    border-radius: 10px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in;
    overflow: hidden;
    color: var(--modal-header-button-text-color);

}

.notificationBox .deletar-notificacao:hover {
    background-color: var(--error-background-hover);
}

/* .notificationBox>svg, */
.userModalContent>svg {
    color: white;
    height: 25px;
    width: 25px;
    margin-right: 2vh;
    fill: var(--secondary-color);
    margin-top: 20px;
}

#notificationContent,
.notificationContent>p {
    font-size: 13px;
    text-transform: capitalize;
    color: var(--modal-header-button-text-color);
}

#notificationName,
.notificationModal span h3 {
    font-size: 15px;
    font-weight: bolder;
    color: var(--modal-header-button-text-color);
}

.notificationContent hr {
    left: 0;
    margin-left: -30px;
    width: 30vh;
    overflow: hidden;
    opacity: 0.4;

}


#btnModal,
#btnNotificacoes,
#btnReleaseNotes {
    padding: 10px 10px;
    outline: none;
    background-color: var(--modal-header-button);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: none;
    border-radius: 10px;
    color: white;
    border: 0px solid transparent;
    font-size: 15px;
    font-weight: bolder;
    width: 230px;
}

/* @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    #btnModal {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }

    #btnModal:hover {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }

    #selectLocales {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }

    #selectLocales:hover {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }

    #btnSair {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }

    #btnSair:hover {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }
}
 */

#btnModal:hover,
#btnNotificacoes:hover,
#btnReleaseNotes:hover {
    background-color: var(--modal-header-button-hover);
    /*     -webkit-backdrop-filter: blur(20px);
    backdrop-filter               : blur(20px); */
}

.localModal>span,
.notificationBox>span {
    margin-top: auto;
    align-self: center;
    background-color: var(--form-background);
    opacity: 0.6;
    width: 90%;
    height: 2px;
    margin-bottom: 1vh;
}

.notificationModal,
.userConfig,
.localModal {
    overflow: auto;
}

.btnModal {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
    width: 40%;
    z-index: 1000;
    background-color: transparent;
    padding: 0.6vh 0 1vh 0;
}

.btnModal:not(:first-child)>* {
    margin-top: 10px;
}


/* CSS do Modal de Locais */
.localModal {
    position: relative;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.searchBarModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchBarModal #btnAdicionar {
    margin-left: 8px;
    height: 40px;
}

.searchBarModal #btnAdicionar p {
    color: white;
}

.searchBarModal #btnAdicionar svg {
    fill: var(--success-background);
}

.searchBarModal .btn .btn-content .btn-icon {
    border: 2px solid white;
    background-color: white;
}

.searchLocal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
}

.localContent {
    overflow: auto;
}

.localBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.localBox button {
    margin: 4px;
}

.localModal h6 {
    align-self: center;
    margin-bottom: 0.5vh;
}

.downloadMessage {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.5vh;
}

.downloadVersion{
    display: flex;
    padding-top: 10px;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.5vh;
}
.downloadBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* border         : 1px solid red; */
}

.downloadBox div {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* border         : 1px solid red; */
}

.downloadBox p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3px;
}

#divisor {
    height: 70%;
    border-right: 1px;
    border: 1px solid var(--form-background);
    opacity: 0.7;
    margin: 0 2vh 0 2vh;
}

#btnDownload {
    border-color: transparent;
    background-color: transparent;
}

#btnDownloadIcon {
    border-color: transparent;
    background-color: transparent;
    margin-left: 4px;
}
#selectLocales {
    display: flex;
    align-items: flex-start;
    padding: 3px 5px;
    width: 100%;
    outline: none;
    background-color: var(--modal-header-button);
    /*     -webkit-backdrop-filter: blur(20px);
    backdrop-filter               : blur(20px); */
    color: var(--modal-header-button-text-color);
    border: 1px solid var(--modal-header-button-border-color);
    margin: 0.5vh;
    font-size: 14px;
    font-weight: bolder;
}

#selectLocales:hover {
    background-color: var(--modal-header-button-hover);
    /*     -webkit-backdrop-filter: blur(20px);
    backdrop-filter               : blur(20px); */
    color: white;
}

#selectLocales>.btn-content {
    display: flex;
    flex-direction: column;
}

#selectLocales>.btn-content>p {
    color: var(--modal-header-button-text-color);
    font-size: 15px;
    font-weight: bolder;
}

#deleteLocales svg {
    height: 37px;
    width: 23px;
    fill: white;
}

/* CSS Modal User */
.userModal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
}

.userModalContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.userModalContent p:nth-child(2n) {
    font-size: 18px;
    font-weight: bolder;
    margin: 1vh;
}

.userModalContent>svg {
    margin: 0.3vh;
}

.userModalContent>#btnModal {
    margin-top: 3vh;
}

.userModalContent>#btnModal:nth-child(5n) {
    margin-top: 10px;
}

#btnSair {
    padding: 10px 0;
    outline: none;
    background-color: var(--modal-header-button);
    /* -webkit-backdrop-filter   : blur(20px);
    backdrop-filter              : blur(20px); */
    border: 1px solid var(--modal-header-button-border-color);
    border-radius: 10px;
    color: var(--modal-header-button-text-color);
    /* border                    : 0px solid transparent; */
    font-size: 15px;
    font-weight: bolder;
    width: 230px;
}

#btnSair:hover {
    background-color: var(--modal-header-button-hover);
    /* -webkit-backdrop-filter: blur(20px);
    backdrop-filter           : blur(20px); */

}

/* CSS Modal Config */
.userConfig {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.userConfig>div {
    width: 90%;
}

.userConfig svg {
    fill: var(--secondary-color);
}

/* Barra de progresso download adapter */
.downloadBox .progress {
    flex-direction: row;
    justify-content: start;
    align-content: flex-start;
}