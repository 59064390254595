/* #root-modal {
    position: fixed;
    top     : 0px;
    left    : 0px;
    width   : 100%;
}

#root-modal .box-modal {
    background-color: var(--modal-bgo);
    display         : block;
    height          : 100vh;
    overflow        : auto;
    padding         : 10px 0 20px 0;
    width           : 100%;
}

#root-modal .box-modal.center {
    align-items: center;
    display    : flex;
    padding    : 0;
}

#root-modal [class*='size-'] {
    background-color: var(--modal-bg);
    border          : 1px solid var(--modal-bgh);
    border-radius   : 4px;
    color           : var(--modal-color);
    display         : block;
    margin          : 0px auto;
    width           : 100%;
}

#root-modal .modal-header {
    align-items    : center;
    border-bottom  : 1px solid var(--modal-bgh);
    display        : flex;
    font-size      : 1.2em;
    justify-content: space-between;
    padding        : 10px 15px;
    box-shadow     : 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05);
}

#root-modal .fullScreen {
    background-color: var(--modal-bg);
    border          : 1px solid var(--modal-bgh);
    color           : var(--modal-color);
    border-radius   : 0px;
    height          : 100vh;
    padding         : 0;
    width           : 100vw;
}

#root-modal .fullScreen .modal-actions {
    margin-right: 15px;
}

#root-modal .fullScreen .modal-content {
    overflow: auto;
    height  : calc(100% - 60px);
}

#root-modal .modal-content {
    font-size : 1em;
    padding   : 10px 15px;
    overflow-y: auto;
    max-height: 600px;
}

#root-modal .modal-content::-webkit-scrollbar {
    display: block;
}

#root-modal .modal-content+.modal-actions {
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
    border-top     : 1px solid var(--modal-bgh);
    padding        : 10px 15px;
}

#root-modal .modal-actions>button {
    margin-left: 10px;
}

#root-modal .modal-close {
    align-items     : center;
    align-self      : flex-start;
    background-color: transparent;
    border          : none;
    border-radius   : 50%;
    color           : var(--modal-color);
    cursor          : pointer;
    display         : flex;
    font-size       : 20px;
    justify-content : center;
    min-height      : 25px;
    min-width       : 25px;
    padding         : 0;
}

#root-modal .modal-close:hover {
    background-color: var(--modal-bgh);
}

#root-modal .modal-close:focus {
    position: relative;
    top     : 1px;
    outline : none;
}

#root-modal .size-small {
    max-width : 300px;
    overflow-y: initial !important;
}

#root-modal .size-medium {
    max-width : 600px;
    overflow-y: initial !important;
}

#root-modal .size-large {
    max-width : 900px;
    overflow-y: initial !important;
}

.header-buttons {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.header-button {
    display        : flex;
    align-items    : center;
    justify-content: flex-end;
}

.header-buttons button {
    margin-left: 1em;

}

.header-button button {
    margin-left: 1em;
}

#root-modal .size-small .header-buttons {
    min-width: 33%;
}

#root-modal .size-medium .header-buttons {
    min-width: 25%;
}

#root-modal .size-large .header-buttons {
    min-width: 17%;
}

#root-modal .size-small .header-button {
    min-width: 33%;
}

#root-modal .size-medium .header-button {
    min-width: 25%;
}

#root-modal .size-large .header-button {
    min-width: 17%;
}
 */

/* 
NOVO LAYOUT
*/
.loader {
    border: 3px solid var(--modal-color); 
    border-radius: 50%;
    border-top: 3px solid var(--modal-header-line);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    /* margin-left: 10px; */
    margin-left: auto;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
#progress-vlan{
    border:1px solid var(--modal-header-line);
    padding: 10px 5px 10px 5px;
    margin-top: 10px;
    display: inline-table;
    background-color: var(--menu-background);
    width: 300px;
}
.progressbar-steps{
    max-height: fit-content;
    padding-bottom: 20px;
}
.progressbar-content{
  padding-top: 5px;
}
.progressbar-step-message{
    margin-top: 10px;
    /* border: 1px solid red; */
    max-height: fit-content;
}
.button-continue:hover{
    cursor: pointer;
    opacity: calc(80%);
}
.button-continue{
    max-width: fit-content;
    margin: 0 auto;
    align-content: center;
    padding-top: 10px;
}
.button-continue div {
    background-color: var(--modal-bg-novo);
    border-radius: 10px;
    padding: 8px;
}
.dots-1 {
    width: 20px;
    margin-left: 3px;
    aspect-ratio: 0.90;
    background: radial-gradient(circle closest-side, var(--modal-color) 30%,#0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: d1 2s steps(4) infinite;
  }
  @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}



#root-modal {
    position  : fixed;
    top       : 0px;
    left      : 0px;
    width     : 100%;
    z-index   : 6;
    background: var(--modal-bgo);
}

#root-modal .box-modal {
    display : block;
    height  : 100vh;
    overflow: auto;
    padding : 60px 0 20px 0;
    width   : 100%;
}

#root-modal .box-modal.center {
    align-items: center;
    display    : flex;
    padding    : 0;
}

#root-modal [class*='size-'] {
    background-color: var(--modal-bg-novo);
    border-radius   : 10px;
    color           : var(--modal-color);
    display         : block;
    margin          : 0px auto;
    width           : 100%;
}

#root-modal .modal-header {
    align-items: center;
    font-size  : 1.2em;
    padding    : 0px 2.5vw 2vh 2.5vw;

}

#root-modal .modal-header h5 {
    opacity       : 1;
    padding-bottom: 2px;
    border-bottom : 1px solid var(--modal-header-line);
}

#root-modal .fullScreen {
    background-color: var(--modal-bg);
    border          : 1px solid var(--modal-bgh);
    color           : var(--modal-color);
    border-radius   : 0px;
    height          : 100vh;
    padding         : 0;
    width           : 100vw;
}

#root-modal .fullScreen .modal-actions {
    margin-right: 15px;
}

#root-modal .fullScreen .modal-content {
    overflow: auto;
    height  : calc(100% - 60px);
}

#root-modal .modal-content {
    font-size        : 1em;
    padding          : 0vh 2.5vw 0vh 2.5vw;
    /* overflow-y    : auto; */
    max-height       : 700px;
    padding-bottom   : 2vh;
}

#root-modal .modal-content::-webkit-scrollbar {
    display: block;
}

#root-modal .modal-content+.modal-actions {
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
    border-top     : 1px solid var(--modal-bgh);
    padding        : 10px 15px;
}

#root-modal .modal-actions>button {
    margin-left: 10px;
}

#root-modal .modal-close {
    display         : flex;
    align-self      : flex-end;
    justify-content : flex-end;
    background-color: transparent;
    color           : var(--modal-color);
    font-size       : 20px;
    min-height      : 25px;
    min-width       : 25px;
    padding         : 10px 10px 0px 0px;
}

#root-modal .modal-btn-close {
    display         : flex;
    align-items     : center;
    justify-content : center;
    border-radius   : 50%;
    width           : 3vh;
    height          : 3vh;
    background-color: var(--modal-ico-close-bg);
    border          : 1px solid var(--modal-ico-border);
}

#root-modal .modal-btn-close>svg {
    fill: var(--modal-x-simbol);
}

#root-modal .modal-btn-close:hover {
    background-color: var(--modal-ico-close-bg-hover);
    border          : 1px solid var(--modal-ico-border-hover);
}

#root-modal .modal-btn-close>svg:hover {
    fill: var(--modal-x-simbol-hover);
}

#root-modal .modal-close:focus {
    position: relative;
    top     : 1px;
    outline : none;
}

#root-modal .size-small {
    max-width : 300px;
    overflow-y: initial !important;
}

#root-modal .size-medium {
    max-width : 500px;
    overflow-y: initial !important;
}

#root-modal .size-large {
    max-width : 900px;
    overflow-y: initial !important;
}

.header-buttons {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.header-button {
    display        : flex;
    align-items    : center;
    justify-content: flex-end;
}

.header-buttons button {
    margin-left: 1em;

}

.header-button button {
    margin-left: 1em;
}

#root-modal .size-small .header-buttons {
    min-width: 33%;
}

#root-modal .size-medium .header-buttons {
    min-width: 25%;
}

#root-modal .size-large .header-buttons {
    min-width: 17%;
}

#root-modal .size-small .header-button {
    min-width: 33%;
}

#root-modal .size-medium .header-button {
    min-width: 25%;
}

#root-modal .size-large .header-button {
    min-width: 17%;
}

#root-modal .modal-close-header {
    width          : 100%;
    display        : flex;
    justify-content: flex-end;
}

#root-modal .modal-footer-button {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-top     : 2vh;
    padding-bottom : 3.5vh;
}

#root-modal .size-small .modal-footer-button {
    margin: 4vh 3vh 0vh 3vh;
}

#root-modal .modal-footer-button>*:not(:first-child) {
    margin-left: 15px;
}

#root-modal .box-modal .row {
    padding-top   : 0px;
    padding-bottom: 0px;
}