.borda {
    margin-top : -10px;
    /*   border: 2px solid red; */
}

.borda>* {
    display        : flex;
    width          : inherit;
    justify-content: center;

}

.texto-fora {
    margin-top: 10px;
    text-align: center;
    color     : var(--auth-page-link-color);
}

.bold-text {
    font-weight: bold;
}

.auth-recover-container .form {
    max-width: 400px;
}

.auth-recover-container .form.email-sended h5,
.auth-recover-container .form.email-sended .texto-email-enviado p {
    border-bottom : 1px solid var(--auth-page-text-border-bottom-color);
    padding-bottom: 10px;
    text-align    : center;
    color         : var(--auth-page-link-color);
}

.link-logar {
    cursor         : pointer;
    text-decoration: underline;
    color          : var(--auth-page-link-color);
}

.auth-recover-container .content .second-column .box-form input:focus::placeholder {}

.texto-email-enviado {
    /* margin-top   : 20px; */
    margin-inline: 10px;
    text-align   : inherit;
    color        : var(--auth-page-link-color);
}

.auth-recover-container {
    display         : flex;
    align-items     : center;
    justify-content : center;
    height          : 100vh;
    width           : 100vw;
    background-color: var(--auth-page-container-background);
}

.auth-recover-container .content .btn {
    border-radius   : 10px;
    color           : var(--auth-page-btn-color);
    font-size       : 13px;
    height          : 33.2px;
    cursor          : pointer;
    font-weight     : bold;
    width           : 150px;
    align-self      : center;
    border          : none;
    margin-top      : 1rem;
    border          : 1px solid var(--auth-page-btn-border);
    background-color: var(--auth-page-btn-background);
    box-shadow      : 0px 3px 6px var(--auth-page-btn-box-shadow-color);
}