.box-vlans {
    position: relative;
}

.background-grey {
    padding-top   : 10px;
    padding-bottom: 10px;

    border-left     : 1px grey solid;
    background-color: rgba(128, 128, 128, 0.089);

    display        : flex;
    align-items    : center;
    justify-content: start;
}

.label-modal {
    padding-right: 10px;
}

.container {
    display        : flex;
    /* border      : 2px aliceblue solid; */
    width          : 100%;
    align-items    : center;
    margin-bottom  : 15px;
    padding-left   : 15px;
    padding-right  : 15px;
    justify-content: space-between;
}