.background {
    display         : flex;
    height          : calc(100vh - 50px);
    background-image: url(../img/networkDots.svg);
    background-size : cover;
    position        : relative;
    height          : 100%;
    width           : 100%;
    overflow        : hidden;
}


.box-app {
    display   : flex;
    height    : calc(100vh - 50px);
    background: var(--page-background);
    color     : var(--page-color);
}

.box-app>* {
    padding: 0.5vh 1vh 1.5vh 1vh;
}

.box-container {
    width     : 100%;
    overflow  : auto;
    background: var(--page-background);
}

.box-container>section {
    padding   : 15px;
    min-height: calc(100% - 37px);
}

.box-container>footer {
    background: var(--footer-background);
    color     : var(--footer-color);
    text-align: center;
    margin-top: auto;
    position  : relative;
    padding   : 10px 15px;
}

.box-header {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    position        : relative;
    min-height      : 50px;
    background-color: var(--header-background);
    box-shadow      : 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05);
}

.box-header>svg {
    font-size: 20px;
}

.box-header button {
    color           : var(--menu-icon-color);
    border          : none;
    background-color: transparent;
    padding         : 14px 13px 15px 13px;
    cursor          : pointer;
}

.box-header button:hover,
.box-header button.open {
    background-color: var(--header-background-hover);
}

.box-sidebar-left {
    min-width       : 304px;
    background-color: var(--sidebar-background);
    border-right    : 2px solid var(--sidebar-background-hover);
    box-shadow      : inset 0px 2px 0px rgba(0, 0, 0, 0.05), 2px 2px 1px rgba(0, 0, 0, 0.05);
    display         : flex;
    flex-direction  : column;
    overflow        : auto;
    z-index         : 2;
    min-height      : calc(100vh - 64px);
}

.box-sidebar-left>div {
    padding        : 16px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
}

@media (max-width: 768px) {
    .box-sidebar-left {
        position  : absolute;
        left      : 0;
        z-index   : 2;
        min-height: calc(100vh - 64px);
    }
}

.box-sidebar-right {
    display         : flex;
    flex-direction  : column;
    min-width       : 305px;
    background-color: var(--sidebar-background);
    border-left     : 2px solid var(--sidebar-background-hover);
    box-shadow      : var(--box-shadow);
    overflow        : auto;
    position        : absolute;
    right           : 0;
    z-index         : 2;
    min-height      : calc(100vh - 50px);
}

.box-sidebar-right>div {
    padding        : 15px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    margin-top     : auto;
}

.box-sidebar-right small {
    align-self: center;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .box-sidebar-right {
        position  : absolute;
        right     : 0;
        z-index   : 2;
        min-height: calc(100vh - 50px);
    }
}

/* ajust padroes */
.box-container>div {
    padding   : 10px 15px;
    min-height: calc(100% - 37px);
}

.barra-pesquisa {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.campo-pesquisa {
    display         : flex;
    align-items     : center;
    /* background   : #22262b;
    border          : 1px solid white;
    border-radius   : 10px; */
}

/* #btnPesquisar {
    background: transparent;
}

#campoPesquisa {
    border    : none;
    background: #22262b;
}

#pesquisar {
    width: 100%;

    border    : none;
    background: #22262b;
} */

.box-conteudo {
    background   : var(--list-background);
    border-radius: 4px;
    margin-bottom: 10px;
    padding      : 15px;
    margin-top   : 10px;
}

.box-btn-group {
    display        : flex;
    justify-content: flex-end;
}

.box-btn-group button {
    margin-left: 10px;
}

#btnMenu {
    stroke: var(--menu-icon-color);
}