.icon {
    display     : inline-block;
    width       : 1em;
    height      : 1em;
    stroke-width: 0;
    stroke      : currentColor;
    fill        : currentColor;
}

.icon-logo-intelbras {
    display     : inline-block;
    width       : 5em;
    height      : 1em;
    stroke-width: 0;
    stroke      : currentColor;
    fill        : currentColor;
}

svg {
    fill: var(--secondary-color);
}

button>svg.icon {
    line-height: 1.5em;
    font-size  : 1.5em;
    float      : left;
    fill       : currentColor;
}

button>svg.icon[viewBox='0 0 24 24'] {
    font-size: 1.7em;
}

button>svg.icon[viewBox='0 0 32 32'] {
    font-size: 1.1em;
}

button>svg.icon[viewBox="0 0 512 512"] {
    font-size: 1.7em;
}

.modal button.close>svg {
    font-size: 1.2em;
}

.spin {
    animation: spin 3s linear infinite;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform        : rotate(360deg);
    }
}