.headerBox .modal-header p {
    color: red;
}

.headerBox .modal-header {
    height         : 450px;
    width          : 400px;
    position       : absolute;
    top            : 60px;
    z-index        : 5;
    border-radius  : .6rem;
    display        : flex;
    align-items    : flex-start;
    justify-content: center;
    padding        : 15px;
    box-shadow     : -3px 6px 10px #00000029;
    border         : 1px solid rgba(255, 255, 255, 0.116);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .headerBox .modal-header {
        background: var(--modal-header-background-color);
    }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .headerBox .modal-header {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }

    .headerBox .modal-header button:not(#linguagem, #tema) {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter        : blur(20px);
    }
}


.headerBox .modal-header.modal-header-config svg {
    color    : var(--modal-header-color);
    font-size: 2em;
}

.headerBox .modal-header.modal-header-config .modal-header-config-content {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    border        : 2px solid yellow;
}

.headerBox .modal-header p {
    color: var(--modal-header-color);
}

.headerBox .modal-header.modal-header-locals {
    right: 5%;
    width: 90%;
}

.headerBox .modal-header.modal-header-config {
    right: 5%;
    width: 90%;
}

.headerBox .modal-header .btn .btn-content .btn-icon {
    margin-right: 0px;
}

@media (min-width: 475px) {
    .headerBox .modal-header.modal-header-locals {
        right: 248px;
        width: 400px;
    }

    .headerBox .modal-header.modal-header-config {
        right: 193px;
        width: 400px;
    }

    .headerBox .modal-header.modal-header-notification {
        right: 148px;
        width: 400px;
    }

    .headerBox .modal-header.modal-header-user {
        right: 5px;
        width: 400px;
    }

}