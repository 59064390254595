.cartao .cartao-rodape .btn-card {
    display                : flex;
    flex-direction         : column;
    align-items            : center;
    justify-content        : center;
    background-color       : transparent;
    border                 : none;
    height                 : 40px;
    font-size              : 10px;
    color                  : var(--card-button-color);
    flex                   : 1;
    transition             : all .1s linear;
    border-top-left-radius : .5rem;
    border-top-right-radius: .5rem;
}

.cartao .cartao-rodape .btn-card:hover {
    /* background-color: rgba(230, 230, 230, 0.5); */
    -webkit-box-shadow: 0px -10px 14px -12px #000000;
    box-shadow        : 0px -10px 14px -12px #000000;
    margin-top        : -4px;
    background-color  : var(--card-background);
}

.cartao .cartao-rodape .btn-card * {
    margin: 2px;
}

/* .cartao .cartao-rodape .btn-card:not(:first-child) {
    border-left    : var(--card-button-border);
    border-collapse: collapse;
} */

.cartao .cartao-rodape .btn-card>svg {
    fill: var(--card-button-color);
}

.cartao .cartao-rodape .btn-card:disabled {
    opacity: 0.5;
}

.cartao .cartao-rodape .btn-card #icon-gerenciar {
    margin: -2px;
    width : auto;
    height: auto;
}

.cartao .cartao-rodape .btn-card #icon-servico-vinculado {
    margin: 2px;
}

.cartao-rodape #btnServicos {
    white-space: nowrap;
    margin     : 0 3px 0 3px;
}

.cartao .cartao-rodape .btn-card #icon-editar {
    margin: 4px;
}