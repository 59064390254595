/* .input-container {
    border-bottom  : 1px solid #048A40;
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 40px;
    position       : relative;
}

.input-container .input-left-icon,
.input-container .input-right-icon {
    height     : 100%;
    display    : flex;
    align-items: center;
}

.input-container .input-left-icon {
    padding-right: 10px;
}

.input-container .input-right-icon {
    padding-left: 10px;
}

.input-container .input-btn svg,
.input-container svg {
    height: 21px;
    width : 21px;
    fill  : #048A40;
}

.input-container .input-field {
    height: 100%;
    border: none;
}

.input-container .input-btn {
    border-radius   : 100%;
    padding         : 3px;
    border          : none;
    background-color: transparent;
}

.input-container .input-btn:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.input-container input+label {
    position  : absolute;
    left      : 31px;
    top       : 25%;
    transition: all .1s linear;
}

.input-container input:focus+label,
.input-container input:required:valid+label {
    top: -10px;
} */

.field-left-icon {
    left: 10px;
}

.auth-container .field-left-icon {
    left: 6px;
}

button.field-left-icon>svg.icon {
    fill: var(--svg-input-button-color);
}

button:hover.field-left-icon>svg.icon {
    fill: var(--svg-input-button-hover-color);
}

.box-form button:not(:first-child)>svg.icon {
    font-size   : 1.2em;
    stroke-width: 2px;
}

.auth-container .box-form button:not(:first-child)>svg.icon {
    font-size   : 1.5em;
    stroke-width: 1px;
}

.invalid-icon {
    height    : 20px;
    margin-top: 2px;
}

.second-column .form .box-form .invalid-icon svg {
    height: 20px;
    fill  : var(--error-background);
}

.second-column .form .box-form .invalid-feedback {
    margin-top   : 0;
    margin-bottom: 10px;
    display      : flex;
    align-items  : flex-end;
}

.box-form>button+input {
    padding-left : 45px;
    padding-right: 40px;
}

.auth-container .box-form>button+input {
    padding-left: 35px;
}

input::placeholder {
    color: var(--input-placeholder-color);
}

/* .box-form>button+:not(input[value='']),
.box-form>button+input:focus {
    padding-left: 45px;
} */

.auth-container .box-form>button+:not(input[value='']),
.auth-container .box-form>button+input:focus {
    padding-left: 35px;
}


.box-form>button:first-child {
    top             : 13.5px;
    background-color: transparent;
    width           : fit-content;
}

.auth-container .box-form>button:first-child {
    top             : 14px;
    background-color: transparent;
}

.auth-container .box-form>button {
    display        : flex;
    align-items    : center;
    justify-content: center;
    position       : absolute;
    top            : 14px;
    right          : 6px;
    padding        : 5px;
    border         : 0;
    border-radius  : 50%;
    background     : transparent;
    color          : var(--form-color);
    z-index        : 2;
}

/* 
.box-form svg {
    fill: #048A40;
} */

/* .box-form>label {
    color: red;
} */