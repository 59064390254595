.menuIcon {
  width: 50px;
  height: 50px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuIcon.active {
  border-radius: 0.3vh;
}

/*  .menuIcon .active {
   border-left  : 0.3em solid white;
   border-radius: 0.3vh;
} */

.menuIcon .opened {
  transform: scale(0.5);
}

.menuIcon * {
  transition: transform 0.5s linear, margin-left 0.5s linear;
  transition-delay: 0.1s;
}

.menuIcon .closed {
  margin-left: -0.7vw;
  transform: scale(0.85);
}
