table {
  width: 100%;
  display: table;
  border-spacing: 4px 5px;
  border-collapse: separate;
}
table thead > tr{
  color: var(--list-color);
  display: table-row;
  outline: 0;
  vertical-align: middle;
}
table tbody > tr {
  color: var(--list-content-color);
  display: table-row;
  outline: 0;
  vertical-align: middle;
}
table tr td {
  background: var(--list-background);
}
table tr:hover td {
  background: var(--list-background-hover);
}
table th {
  background: var(--list-header-background);
  color: var(--list-color);
  font-weight: 600;
  padding: 8px;
}

table th,
table td {
  display: table-cell;
  padding: 5px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  /* border-top: 1px solid var(--list-background-border);
  border-bottom: 1px solid var(--list-background-border); */
  vertical-align: inherit;
  letter-spacing: 0.01071em;
}

table tr th:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
table tr th:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
table tr td:first-child {
  /* border-left: 1px solid var(--list-background-border); */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
table tr td:last-child {
  /* border-right: 1px solid var(--list-background-border); */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

table .actions {
  text-align: center;
  width: 1%;
  white-space: nowrap;
  padding: 2px 5px;
}
table td > button {
  margin-left: 5px;
}
table td > button:first-child {
  margin-left: 0px;
}
table .text-center {
  text-align: center;
}
table .text-rigth {
  text-align: right;
}

.checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
}

table .text-center.table-id{
  width: 15%
}
