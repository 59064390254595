.progress {
  display         : -ms-flexbox;
  display         : flex;
  height          : 0.75rem;
  overflow        : hidden;
  font-size       : 0.75rem;
  background-color: #e9ecef;
  border-radius   : 0.2rem;
}

.progress-bar {
  display           : -ms-flexbox;
  display           : flex;
  -ms-flex-direction: column;
  flex-direction    : column;
  -ms-flex-pack     : center;
  justify-content   : center;
  overflow          : hidden;
  color             : #fff;
  text-align        : center;
  white-space       : nowrap;
  background-color  : #007bff;
  transition        : width 0.6s ease;
}

.progress-danger {
  background-color: #dc3545;
}