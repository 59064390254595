.box-menu li a {
  color: var(--menu-color);
  text-decoration: none;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.2px;
  display: block;
  padding: 12px;
}
.box-menu li a:hover {
  background-color: var(--menu-background);
  color: var(--menu-color);
}
.box-menu li.active {
  background-color: var(--menu-background);
  color: var(--menu-color);
}

.box-menu li.development{
  background-color: var(--error-background);
}

.box-menu li.development a:hover{
  background-color: var(--error-background-hover);
}

.box-menu li.active.development{
  background-color: var(--error-background-hover);
}
