.box-servicos-cpe {
    padding   : 10px 15px;
    min-height: calc(100% - 37px);
    position  : relative;
}

.box-servicos-lista {
    background   : var(--list-background);
    border-radius: 4px;
    margin-bottom: 10px;
    padding      : 10px;
}

.box-servicos-lista button {
    margin-left: 5px;
}

.box-servicos-lista p {
    margin-bottom: 10px;
}

.box-servicos-cpe .box-conteudo div label,
.box-servicos-cpe .box-conteudo div p {
    opacity: 0.9;
    color  : var(--menu-color);
}

.box-servicos-cpe {
    position: relative;
}

.background-grey {
    padding-top   : 10px;
    padding-bottom: 10px;

    border-left     : 1px grey solid;
    background-color: rgba(128, 128, 128, 0.089);

    display        : flex;
    align-items    : center;
    justify-content: start;
}

.label-modal {
    padding-right: 10px;
}

.box-servicos-cpe .box-conteudo div label,
.box-servicos-cpe .box-conteudo div p {
    opacity: 0.9;
    color  : var(--menu-color);
}

/* .box-conteudo button:not(:first-child) {
    margin-left: 10px;
} */

.box-servicos-cpe .box-conteudo {
    background-color: var(--olt-informacao-background-color);
    border-radius   : 10px;
    border          : 1px solid var(--olt-informacao-border-color);
    box-shadow      : var(--olt-informacao-box-shadow);
}

.box-conteudo button.btn.normal.success {
    background-color: var(--success-background-novo);
}

.box-conteudo button.btn.normal.success p {
    color: var(--olt-informacao-button-color);
}

.box-conteudo .row.show {
    padding-top    : 0;
    padding-bottom : 0;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.box-conteudo .btn .btn-content .btn-icon {
    display         : inherit;
    border          : none;
    background-color: transparent;
    border-radius   : 0;
}

.box-conteudo .btn .btn-content .btn-icon svg {
    fill: white;
}

.box-conteudo .row.show [class*='col-md-'] {
    margin            : 0;
    overflow          : hidden;
    /* padding-top    : 10px; */
    display           : flex;
    flex-direction    : column;
    align-items       : center;
    justify-content   : center;
    padding-left      : 0;
    padding-right     : 0;
}

.box-conteudo .row.show .section-divisor {
    width           : 1px;
    height          : 50px;
    background-color: var(--olt-informacao-divisor-color);
}

.box-conteudo .row.show [class*='col-md-'].buttons .section-divisor {
    height: 50px;
}

.box-conteudo .row.show [class*='col-md-'].buttons {
    height         : auto;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-evenly;
    flex-wrap      : wrap;
    padding-top    : 0;
}

@media screen and (max-width: 768px) {
    .box-conteudo .row.show [class*='col-md-'] {
        margin-top   : 10px;
        margin-bottom: 10px;
    }

    .box-conteudo .row.show .section-divisor {
        width           : 100%;
        height          : 1px;
        background-color: var(--olt-informacao-divisor-color);
    }

    .box-conteudo .row.show [class*='col-md-'].buttons>.section-divisor {
        position           : relative;
        /* top             : 5px; */
        height             : 100%;
        width              : 1px;
        background-color   : var(--olt-informacao-divisor-color);
    }

    .box-conteudo .row.show [class*='col-md'].buttons {
        padding-left : 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 360px) {
    .box-conteudo .row.show .buttons .btn {
        padding  : 0;
        font-size: 12px;
    }
}

@media screen and (max-width: 320px) {
    .box-conteudo .row.show .buttons .btn {
        padding  : 0;
        font-size: 11px;
    }
}

@media screen and (max-width: 375px) {
    .box-conteudo .row.show .buttons .btn {
        padding: 0;
    }
}