.titleContainer {
    display         : table;
    margin: 0 auto;
    width           : fit-content;
    background-color: var(--title-container-background);
    padding      : 0.6vh 2vh 0.6vh 2vh;
    border-radius: 10px;
    box-shadow   : 0px 4px 3.5px rgb(0 0 0 / 25%);
}

.titleContainer h1 {
    color  : #fff;
    opacity: 1;
}