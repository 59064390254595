.pagination-custom {
  display        : flex;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  margin         : 15px 0 20px 0;
}

.pagination-custom>span.pagination-info {
  text-align: center;
  font-size : 80%;
  margin-top: 2px;
  color     : var(--fontColors);
}

.pagination-custom>div {
  display: inline-block;
}

.pagination-custom .btn.normal.secondary {
  background-color: transparent;
  border          : transparent;
  margin          : 0 0px 0 -2px;
  padding         : 0 !important;
}

.btn-pagina {
  fill: var(--fontColors);
}

.pagination-custom .btn.normal.secondary:nth-child(2) {
  border-radius: 5px 0px 0 5px;
  border       : 2px solid var(--secondary-color);
  margin-left  : 7px;
  min-height   : 25px;
  min-width    : 25px;
}

.pagination-custom .btn.normal.secondary:nth-child(3) {
  border-radius: 0px 5px 5px 0px;
  border       : 2px solid var(--secondary-color);
  margin-right : 7px;
  min-height   : 25px;
  min-width    : 25px;
}

.pagination-custom .page-item input {
  height          : 100%;
  width           : 100px;
  padding         : 0px 1px 0px 2px;
  font-size       : 1rem;
  line-height     : 1.5;
  color           : var(--fontColors);
  background-color: transparent;
  border          : var(--form-border);
  float           : left;
  border-top      : none;
  border-right    : none;
  border-left     : none;
}

#go {
  border-right : none;
  border-left  : none;
  border-top   : none;
  border-radius: 0;
  margin-left  : 9px;
  padding      : 0px;
  margin-top   : -2px;
}

#go .btn-content {
  margin-top: 3px;
  color     : var(--fontColors);
}

.pagination-custom .btn.normal.secondary {
  border-top-left-radius   : 0px;
  border-bottom-left-radius: 0px;
}

.pagination-custom p span {
  padding         : 1.5px 4px;
  background-color: var(--page-indicator);
  border          : 1px solid rgb(253, 253, 253);
  border-radius   : 3px;
}