.headerBox {
    display         : flex;
    align-items     : center;
    position        : relative;
    justify-content : center;
    min-height      : 4em;
    background-color: var(--header-background);
    box-shadow      : -8px 0px 17px -1px rgb(0 0 0 / 22%);
    border-radius   : 10px;
    margin          : 0.5vh 1vh 0vh 1vh;
}


.headerBox #btnMenu {
    position: absolute;
    left    : 1.5vh;
}

.headerBox .intelbras-icon {
    align-self: center;
}

.iconMenu {
    fill  : transparent;
    border: transparent;
}

.rightAction {
    display       : flex;
    align-items   : center;
    flex-direction: row;
    right         : 1.5vh;
    position      : absolute;
}
.statusApplication {
    padding: 1vh;
}
.statusApplication span{
    font-weight:bold;
    font-size: large;
    
}
#spanStatus{
    color: white;
}
.white-box{
    background-color: white;
    display:inline-block;
    padding: 4px;
    border-radius: 10px;
}
#btnMenu {
    stroke          : var(--menu-icon-color);
    background-color: transparent;
    border          : transparent;
    outline         : none;
    padding-top     : 5px;
    padding-left    : 12px;
}

#btnLocation {
    width           : fit-content;
    height          : fit-content;
    background-color: transparent;
    border          : transparent;
    padding         : 1vh;
}

#btnConfig {
    background-color: transparent;
    border          : transparent;
    padding         : 1vh;
    padding-top: 15px;
}

#btnNotification {
    background-color: transparent;
    border          : transparent;
    padding         : 1vh 1vh 1vh 1vh;
    margin-right    : -0.5vh;
    position        : relative;
}

#btnNotificationHasNotification {
    background-color: transparent;
    border          : transparent;
    padding         : 1vh 1vh 1vh 1vh;
    margin-right    : -0.5vh;
}

.hasNotification{
    position        : absolute;
    width           : 15px;
    height          : 15px;
    top             : 1vh;
    right           : 1vh;
    background-color: red;
    border-radius   : 50%;
    display         : flex;
    justify-content : center;
    align-items     : center;
    border          : 2px solid var(--header-background);
}

.hasNotification>span {
    font-size: 10px;
    color    : white;
}

#btnUser {
    display         : flex;
    align-items     : center;
    flex-direction  : row;
    border          : transparent;
    background-color: transparent;
    padding-left    : 1vh;
}

#btnMenu>svg:hover {
    stroke: var(--menu-icon-color-hover);
}

#btnLocation>svg:hover,
#btnConfig>svg:hover,
#btnNotification>svg:hover {
    fill: var(--menu-icon-color-hover);
}

#btnLocation>svg,
#btnConfig>svg,
#btnNotification>svg {
    fill: var(--menu-icon-color)
}

.userBox:hover>button * {
    fill : var(--menu-icon-color-hover);
    color: var(--menu-icon-color-hover);
}

.separator {
    background-color: var(--menu-icon-color);
    min-width       : 1px;
    height          : 3vh;
}

.userInfo,
.userInfo>p {
    display       : flex;
    align-items   : center;
    flex-direction: column;
    padding-left  : 0.3vh;
    color         : var(--menu-icon-color);
}

#btnCog {
    width : 3.5vh;
    height: 3.5vh;
    fill  : var(--menu-icon-color);
}

.intelbras-icon {
    fill : var(--intelbrasLogo);
    color: var(--intelbrasLogo);
}

@media screen and (max-width: 500px) {
    .headerBox {
        display        : flex;
        align-items    : center;
        justify-content: center;
    }

    #btnMenu {
        display     : block;
        position    : absolute;
        left        : 0;
        top         : 50;
        padding-left: 2vh;
    }

    #btnMenu>svg:hover {
        stroke: var(--menu-icon-color);
    }

    .rightAction {
        display: none;
    }
}